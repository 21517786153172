import { UpsellSharedModalProps } from '@features/Stripe/widgets/upsell-modal-renderer';
import { UpsellAgreeAndDeclineButtons } from '@features/Upsell/components/upsell-agree-and-decline-buttons';
import { UpsellYearlyCoffeeCup } from '@features/Upsell/components/upsell-yearly-coffee-cup';
import { UpsellYearlyDescription } from '@features/Upsell/components/upsell-yearly-description';
import { UpsellYearlyGraph } from '@features/Upsell/components/upsell-yearly-graph';
import { UpsellYearlyPricePerDay } from '@features/Upsell/components/upsell-yearly-price-per-day';
import { UpsellYearlyTitle } from '@features/Upsell/components/upsell-yearly-title';
import { memo, useCallback, useEffect } from 'react';
import Modal from 'src/components/common/modal/modal/modal';
import { useAnalytics } from '@features/Analytics';
import { currencyReplacer } from '@features/Payments';
import { premiumYearAllDataAtom, selectedPlanAtom, upsellInfoAtom, userAtom } from '@features/Stripe/atoms';
import { useModifySubscription } from '@features/Stripe/hooks/mutation/use-modify-subscription';
import { UpsellLoader } from '@features/Upsell/components/upsell-loader';
import { FormattedMessage } from '@features/intl';
import { useAtomValue } from 'jotai/index';
import { replaceDecimalsWithNines } from 'src/utils/replace-decimals-with-nines';
import styles from './upsell-yearly-premium.module.scss';
export const UpsellYearlyPremium = memo(({
  onNext
}: UpsellSharedModalProps) => {
  const {
    mutateAsync: modifySubscription
  } = useModifySubscription();
  const {
    currency,
    premiumDiscountPriceData,
    fullPriceDiscount: currentFullPriceDiscount
  } = useAtomValue(selectedPlanAtom);
  const {
    fullPriceDiscount: ebookPrice
  } = useAtomValue(upsellInfoAtom);
  const {
    email,
    userId
  } = useAtomValue(userAtom);
  const {
    premiumYearData
  } = useAtomValue(premiumYearAllDataAtom);
  const {
    trackGoogleEvent
  } = useAnalytics();
  const scannerPrice = replaceDecimalsWithNines(ebookPrice * 4);
  const currencySymbol = currencyReplacer(currency);
  const yearlyPlanFakePrice = (Number(premiumYearData?.fullPriceDiscount) - Number(premiumDiscountPriceData?.fullPriceDiscount)).toFixed(2);
  const upgradedPricePerDay = ((Number(premiumYearData?.fullPriceDiscount) - Number(currentFullPriceDiscount)) / 365).toFixed(2);
  const handleBuy = useCallback(() => {
    trackGoogleEvent({
      eventName: 'looking-to-increase_button_try-premium_click'
    });
    if (premiumYearData?.upgradeId) {
      modifySubscription({
        email,
        userId,
        newPlanId: premiumYearData?.upgradeId
      }).then(r => r);
      onNext('MODAL_UPSELL');
    }
  }, [onNext, modifySubscription, email, userId, premiumYearData?.upgradeId, trackGoogleEvent]);
  const handleDecline = useCallback(() => {
    trackGoogleEvent({
      eventName: 'looking-to-increase_button_decline_click'
    });
    onNext('UPSELL_COMMITMENT_HARD');
  }, [onNext, trackGoogleEvent]);
  useEffect(() => {
    trackGoogleEvent({
      eventName: 'looking-to-increase_screen__loaded'
    });
  }, [trackGoogleEvent]);
  return <Modal modalContentClassName={styles.container} show={true} canClose={false}>
        {!premiumDiscountPriceData || premiumYearData.fullPriceDiscount == 0 ? <UpsellLoader /> : <div>
            <UpsellYearlyTitle />

            <UpsellYearlyDescription />

            <UpsellYearlyGraph />

            <UpsellYearlyPricePerDay currency={currencySymbol} pricePerDay={Number(upgradedPricePerDay)} />

            <UpsellYearlyCoffeeCup />

            <div className={styles.billSection}>
              <div className={styles.billItem}>
                <p className={styles.billItemLabel}>
                  <FormattedMessage defaultMessage="Plan enhancements" id="Onboarding.PremiumTotal.Line1" />
                </p>
                <p className={styles.billItemPrice}>
                  <del>
                    {currencySymbol}
                    {scannerPrice}
                  </del>
                  {currencySymbol}0
                </p>
              </div>
              <div className={styles.billItem}>
                <p className={styles.billItemLabel}>
                  <FormattedMessage defaultMessage="Yearly Plan" id="Onboarding.PremiumTotal.Line2" />
                </p>
                <p className={styles.billItemPrice}>
                  {currencySymbol}
                  {yearlyPlanFakePrice}
                </p>
              </div>
              <div className={styles.billItem}>
                <p className={styles.billItemLabel}>
                  <FormattedMessage defaultMessage="Premium" id="Onboarding.PremiumTotal.Line3" />
                </p>
                <p className={styles.billItemPrice}>
                  {currencySymbol}
                  {premiumDiscountPriceData?.fullPriceDiscount.toFixed(2)}
                </p>
              </div>

              <div className={styles.billTotal}>
                <p className={styles.billTotalLabel}>
                  <FormattedMessage defaultMessage="Total:" id="Onboarding.PremiumTotal.TotalPrice" />
                </p>
                <p className={styles.billTotalPrice}>
                  <del>
                    {currencySymbol}
                    {premiumYearData.fullPrice.toFixed(2)}
                  </del>
                  {currencySymbol}
                  {premiumYearData.fullPriceDiscount.toFixed(2)}
                </p>
              </div>
            </div>

            <UpsellAgreeAndDeclineButtons testIdBuyButton="yearly-premium-buy-button" testIdDeclineButton="yearly-premium-decline-button" className={styles.buttonsContainer} handleDecline={handleDecline} handleBuy={handleBuy} />
          </div>}
      </Modal>;
});
UpsellYearlyPremium.displayName = 'UpsellYearlyPremium';