import { FormattedMessage } from '@features/intl';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';
import styles from './upsell-policy.module.scss';
export const UpsellPolicy = () => {
  return <>
      <p className={styles.policyFirstLine}>
        <FormattedMessage id="Onboarding.Premium.Terms1" defaultMessage="By purchasing, you agree to our {TOS} {PrivacyPolicy} {RefundPolicy1} {RefundPolicy2}" values={{
        TOS: <TermsLink place="upsale">
                <FormattedMessage defaultMessage="Terms of Service," id="Onboarding.Premium.Terms1.TOS" />
              </TermsLink>,
        PrivacyPolicy: <PolicyLink place="upsale">
                <FormattedMessage defaultMessage="Privacy Policy," id="Onboarding.Premium.Terms1.PrivacyPolicy" />
              </PolicyLink>,
        RefundPolicy1: <RefundLink place="upsale">
                <FormattedMessage defaultMessage="Money-Back Guarantee," id="Onboarding.Premium.Terms1.RefundPolicy1" />
              </RefundLink>,
        RefundPolicy2: <RefundLink place="upsale">
                <FormattedMessage defaultMessage="Refund and Cancellation Policy." id="Onboarding.Premium.Terms1.RefundPolicy2" />
              </RefundLink>
      }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </p>
      <p className={styles.underScrollText}>
        <FormattedMessage id="Onboarding.Premium.Disclaimer" defaultMessage="Mimika will use your payment details to seamlessly process future payments. All transactions will be converted into USD based on your bank’s exchange rate at the time of payment. Subscriptions renew automatically at the end of the billing cycle, unless you cancel at least 24 hours in advance. To avoid being charged, cancel your subscription either in the app or by contacting our support team at least 24 hours before the end of the billing cycle. Please contact our support team at support@mimika-app.com if you have any questions about how to cancel. You may also want to take a screenshot of this information for your reference." data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </p>
    </>;
};