import classNames from "classnames";
import { memo, useCallback, useMemo } from "react";
import { TpPlanCardProps } from "src/features/Paywall/types";
import { PaddleInlineCheckout } from "@features/Payments/components/paddle-inline-checkout";
import styles from "./master.module.scss";
export const SubscriptionCardMaster = memo((props: TpPlanCardProps) => {
  const {
    plan,
    prefix,
    onSelectPlan,
    disabled,
    isInlinePaymentActive,
    fullWidthBottomContent,
    leftBottomContent,
    rightContent,
    contentClassname,
    planCardClassname,
    descriptionContent,
    isCardSelected = false,
    //added for a/b old plan cards cause remove hardcoded hideDescription overwrite affect a lot
    hidePlanTitle = true
  } = props;
  const namespace = `${prefix}_subscription-plan-card`;
  const inputId = `${namespace}_${plan?.id}`;
  const isSelected = isCardSelected;
  const onChange = useCallback(() => onSelectPlan && plan?.id != null &&
  //@ts-expect-error stripe fields
  onSelectPlan(plan.id, plan?.paddlePlanId),
  //@ts-expect-error stripe fields
  [onSelectPlan, plan?.id, plan?.paddlePlanId]);
  const renderPlanName = useMemo(() => plan?.hideDescription && hidePlanTitle ? null : <div className={classNames(styles.titleWrapper, {
    [styles.titleWrapperChecked]: isSelected
  })}>
          <h5 className={styles.titleCard}>
            <span>{plan?.description}</span>{" "}
            {descriptionContent && descriptionContent(isSelected)}
          </h5>
        </div>, [descriptionContent, isSelected, plan?.description, plan?.hideDescription, hidePlanTitle]);
  return <li className={classNames(styles.wrapper, {
    [styles.wrapperDisabled]: disabled,
    [styles.wrapperInvisible]: disabled || !isSelected && isInlinePaymentActive,
    [styles.wrapperGreen]: isSelected && isInlinePaymentActive
  })}>
      <input disabled={disabled} className={classNames(styles.input, {
      [styles.checked]: isSelected
    })} type="radio" id={inputId} name={namespace} value={plan.id} checked={isSelected} onChange={onChange} />

      <label htmlFor={inputId} className={classNames(disabled && styles.planCardDisabled, styles.planCard, planCardClassname)}>
        {renderPlanName}
        <div className={classNames(styles.content, contentClassname)}>
          <div className={styles.leftBlock}>
            <h4>{plan.name}</h4>
            {leftBottomContent && leftBottomContent(isSelected)}
          </div>
          <div className={styles.rightBlock}>
            {rightContent && rightContent(isSelected)}
          </div>
        </div>
        {fullWidthBottomContent && fullWidthBottomContent(isSelected)}
      </label>

      {isCardSelected && isInlinePaymentActive && <PaddleInlineCheckout
    //@ts-expect-error stripe fields
    subscriptionPlan={plan}
    //@ts-expect-error stripe fields
    productId={plan.paddlePlanId} />}
    </li>;
});
SubscriptionCardMaster.displayName = 'SubscriptionCardMaster';