import { axiosMicroservice } from 'src/api/axios';

const modifyUrlMap = {
  braintree: 'braintree_upgrade',
  stripe: 'stripe/modify_subscription',
};

export type TpModifySubscription = {
  newPlanId: string;
  userId: number;
  email: string;
  app?: string;
  paymentSystem?: 'braintree' | 'stripe';
};

export const modifySubscription = async ({
  newPlanId,
  userId,
  email,
  app = 'FaceYoga',
  paymentSystem = 'stripe',
}: TpModifySubscription): Promise<any> => {
  return await axiosMicroservice.post(`${modifyUrlMap[paymentSystem]}`, {
    user_id: userId,
    new_plan_id: newPlanId,
    email: email,
    app,
  });
};
