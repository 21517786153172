import { useQuery } from '@tanstack/react-query';

import { getStripeSubscriptions } from '@features/Stripe/api';
import { useGetAppName } from 'src/utils/hooks';

export const useGetStripeSubscriptions = ({
  productId,
}: {
  productId: string | undefined;
}) => {
  const app = useGetAppName();
  const { data } = useQuery({
    queryKey: ['stripe-subscriptions', productId, app],
    queryFn: () => getStripeSubscriptions({ productId, app }),
    enabled: !!productId && !!app,
  });

  return data;
};
