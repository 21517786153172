import { useAnalytics } from '@features/Analytics';
import { MimikaLogoWithText } from '@features/Quiz/components/welcome-loader';
import { UpsellSharedModalProps } from '@features/Stripe/widgets/upsell-modal-renderer';
import { FormattedMessage } from '@features/intl';
import { useCallback, useEffect } from 'react';
import { Image } from 'src/components/common/Image';
import Alert from 'src/components/common/modal/alert/alert';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';
import styles from './premium-info-static-alert.module.scss';
export const PremiumInfoStaticAlert = ({
  onNext
}: UpsellSharedModalProps) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const handleNext = useCallback(() => {
    trackGoogleEvent({
      eventName: 'did-you know_button_continue_click'
    });
    onNext('UPSELL_SCANNER');
  }, [onNext, trackGoogleEvent]);
  useEffect(() => {
    trackGoogleEvent({
      eventName: 'did-you know_screen__loaded'
    });
  }, [trackGoogleEvent]);
  return <Alert modalContentClassName={styles.container} titleClassName={styles.modalTitle} backdropClassName={styles.backdrop} canClose={false} show={true} data-sentry-element="Alert" data-sentry-component="PremiumInfoStaticAlert" data-sentry-source-file="index.tsx">
      <div className={styles.content}>
        <h1 className={styles.title}>
          <FormattedMessage defaultMessage="Did you know" id="Onboarding.Upsale.Popup.Title" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        </h1>
        <p>
          <FormattedMessage defaultMessage="Mimika members, who start their program" id="Onboarding.Upsale.Popup.Text1" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
          <br />
          <FormattedMessage defaultMessage="with {mark}" id="Onboarding.Upsale.Popup.Text2" values={{
          mark: <b>
                  <FormattedMessage defaultMessage="Premium," id="Onboarding.Upsale.Popup.Text2.Mark" />
                </b>
        }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
          &nbsp;
          <FormattedMessage defaultMessage="get long-lasting visible" id="Onboarding.Upsale.Popup.Text3" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
          <br />
          <FormattedMessage defaultMessage="results {mark}" id="Onboarding.Upsale.Popup.Text4" values={{
          mark: <b>
                  <FormattedMessage defaultMessage="38% faster" id="Onboarding.Upsale.Popup.Text4.Mark" />
                </b>
        }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        </p>

        <div className={styles.graphContainer}>
          <div className={styles.rateLabel}>
            <FormattedMessage defaultMessage="x1,5 to the result rate" id="Onboarding.Upsale.Popup.Note" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
          </div>
          <div className={styles.graphLinePremiumContainer}>
            <div className={styles.graphLinePremium}>
              <MimikaLogoWithText className={styles.mimikaLogo} filledLogo data-sentry-element="MimikaLogoWithText" data-sentry-source-file="index.tsx" />
              <p className={styles.premiumLabel}>premium</p>
            </div>
            <Image src="champion-cup-emoji.webp" alt="" width={40} height={40} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
          </div>
          <div className={styles.graphLine}>
            <MimikaLogoWithText className={styles.mimikaLogo} filledLogo data-sentry-element="MimikaLogoWithText" data-sentry-source-file="index.tsx" />
          </div>
        </div>

        <div className={styles.discountDescription}>
          <FormattedMessage defaultMessage="We want you to succeed, that’s why we are" id="Onboarding.Upsale.Popup.Subtext1" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
          <br />
          <FormattedMessage defaultMessage="offering {mark}" id="Onboarding.Upsale.Popup.Subtext2" values={{
          mark: <mark>25% off</mark>
        }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
          &nbsp;
          <FormattedMessage defaultMessage="on our Premium Plan!" id="Onboarding.Upsale.Popup.Subtext3" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        </div>
      </div>

      <UiKitButton fullWidth onClick={handleNext} className={styles.continueButton} data-testid="premium-static-continue-info-button" data-sentry-element="UiKitButton" data-sentry-source-file="index.tsx">
        <FormattedMessage defaultMessage="Continue" id="Onboarding.Upsale.Popup.Button" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </UiKitButton>

      <p className={styles.terms}>
        <FormattedMessage id="Onboarding.Paywall.Main.Terms" defaultMessage="By purchasing, you agree to our {privacy}, {terms}, {mark} {subscription}" values={{
        privacy: <PolicyLink place="paywall" />,
        terms: <TermsLink place="paywall" />,
        subscription: <RefundLink place="paywall" />,
        mark: <span>
                <FormattedMessage defaultMessage="and" id="Onboarding.Paywall.Main.Terms.Mark" />
              </span>
      }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </p>
    </Alert>;
};