import { FormattedMessage } from '@features/intl';
import styles from './upsell-yearly-description.module.scss';
export const UpsellYearlyDescription = () => {
  return <p className={styles.description} data-sentry-component="UpsellYearlyDescription" data-sentry-source-file="index.tsx">
      <FormattedMessage defaultMessage="People feel obligated to follow through with something when they have a strong commitment." id="Onboarding.PremiumTotal.Text1" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      <br />
      <br />
      <FormattedMessage defaultMessage="Stopping aging with a good skincare routine is based on this principle. Commit to the new you, you just need to." id="Onboarding.PremiumTotal.Text2" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      <br />
      <br />
      <FormattedMessage defaultMessage="Mimika users who commit today are 46% more likely to achieve their ideal fresh look than those who choose to travel short distances." id="Onboarding.PremiumTotal.Text3" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
    </p>;
};