import { useMemo } from "react";

import { currencyReplacer } from "@features/Payments";
import { TpCardPlanChildrenProps } from "@features/Paywall/types";
import { useFeature } from "../../../../FeatureToggle";

type UseCurrentPriceProps = {
  plan: TpCardPlanChildrenProps["plan"];
};

export const useCurrentPrice = ({ plan }: UseCurrentPriceProps) => {
  //TODO: Delete after cleanup tests
  const isIntroOfferEnabled = useFeature('introOfferFeature');

  const fractionalPriceDiscount = useMemo(() => {
    if (isIntroOfferEnabled) {
      return plan?.introOfferData?.fractionalPriceDiscount;
    } else {
      return plan.fractionalPriceDiscount;
    }
  }, [
    plan?.introOfferData?.fractionalPriceDiscount,
    plan.fractionalPriceDiscount,
    isIntroOfferEnabled,
  ]);

  const initialPrice =
    //@ts-expect-error stripe fields
    plan.initialPrice !== undefined
      ? //@ts-expect-error stripe fields
        plan.initialPrice / plan.trialPeriodDays
      : null;

  const formattedPrice = useMemo(() => {
    if (initialPrice !== null) {
      return initialPrice.toFixed(2).toString().slice(2);
    } else if (fractionalPriceDiscount?.toString().split('.')[1] != null) {
      return Number.parseFloat(
        fractionalPriceDiscount
          .toString()
          .split('.')[1]
          .toString()
          .padEnd(2, '0'),
      ).toString();
    } else {
      return '00';
    }
  }, [initialPrice, fractionalPriceDiscount]);

  //@ts-expect-error stripe fields
  const displayPrice = plan.paddlePlanId
    ? plan.displayPrice
    : `per ${plan.displayPrice}`;

  const currency = currencyReplacer(plan.currency);

  const priceDigit =
    initialPrice !== null
      ? Number(formattedPrice[0])
      : Number(
          Number.parseFloat(
            fractionalPriceDiscount?.toString().split('.')[0] ?? '0',
          ),
        );

  const isLongCurrencies = priceDigit.toString().length > 2;

  return useMemo(
    () =>
      !plan
        ? {
            displayPrice: null,
            formattedPrice: '00',
            currency: '',
            priceDigit: 0,
            isLongCurrencies: false,
          }
        : {
            displayPrice,
            formattedPrice,
            currency,
            priceDigit,
            isLongCurrencies,
          },
    [
      plan,
      displayPrice,
      formattedPrice,
      currency,
      isLongCurrencies,
      priceDigit,
    ],
  );
};
