import { useMutation } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { createInvoice } from '@features/Stripe/api';
import { stripeWasPaymentTriedAtom } from '@features/Stripe/atoms';
import { TpStripeCreateInvoice } from '@features/Stripe/types';
import { useGetAppName } from 'src/utils/hooks';

export const useCreateInvoice = () => {
  const app = useGetAppName();
  const setCrossSailOffer = useSetAtom(stripeWasPaymentTriedAtom);
  const handleHideCrossSailOffer = useCallback(
    () =>
      setCrossSailOffer((previous) => ({
        ...previous,
        showCrossSailOffer: false,
      })),
    [setCrossSailOffer],
  );
  return useMutation({
    mutationFn: (variables: TpStripeCreateInvoice) =>
      createInvoice({
        ...variables,
        app,
      }),
    mutationKey: ['create-invoice'],
    onSuccess: handleHideCrossSailOffer,
    onError: handleHideCrossSailOffer,
  });
};
