import { useMemo } from "react";
import { TpCardPlanChildrenProps } from "@features/Paywall/types";
import { useFeature } from "../../../../FeatureToggle";
import styles from "./billing-period.module.scss";
type TpBillingPeriodProps = {
  plan: TpCardPlanChildrenProps["plan"];
};
export const BillingPeriod = (props: TpBillingPeriodProps) => {
  const {
    plan
  } = props;
  //TODO: Delete after cleanup tests
  const isIntroOfferEnabled = useFeature('introOfferFeature');
  const billingPeriod = useMemo(() => isIntroOfferEnabled ? `${plan?.introOfferData?.billingPeriod} ${plan?.introOfferData?.billingPeriodUnit}` : `${plan.billingPeriod} ${plan.billingPeriodUnit}`, [plan.billingPeriod, plan.billingPeriodUnit, plan?.introOfferData?.billingPeriod, plan?.introOfferData?.billingPeriodUnit, isIntroOfferEnabled]);
  return <span className={styles.perPeriod} data-sentry-component="BillingPeriod" data-sentry-source-file="index.tsx">{`per ${billingPeriod}`}</span>;
};