import { FormattedMessage as Message } from "react-intl";
import { TpFormattedMessage } from "../../types";
export const FormattedMessage = (props: TpFormattedMessage) => {
  const {
    defaultMessage,
    id,
    ...otherProps
  } = props;
  return <span data-trans={id} data-sentry-component="FormattedMessage" data-sentry-source-file="index.tsx">
      <Message data-trans={id} id={id} defaultMessage={defaultMessage} {...otherProps} data-sentry-element="Message" data-sentry-source-file="index.tsx" />
    </span>;
};