import { FormattedMessage } from '@features/intl';
import { Image } from 'src/components/common/Image';
import styles from './premium-header.module.scss';
const BENEFITS = [{
  imageUrl: 'strong-arm-emoji.webp',
  text: <FormattedMessage defaultMessage="Focus Area: {br} Maximize your {br} training" id="Onboarding.Upsale.Extra1" values={{
    br: <br />
  }} />
}, {
  imageUrl: 'lovely-emoji.webp',
  text: <FormattedMessage defaultMessage="Skin care {br} practices that {br} bring you joy" id="Onboarding.Upsale.Extra2" values={{
    br: <br />
  }} />
}, {
  imageUrl: 'champion-cup-emoji.webp',
  text: <FormattedMessage defaultMessage="Life-changing {br} result with our {br} support" id="Onboarding.Upsale.Extra3" values={{
    br: <br />
  }} />
}];
export const PremiumHeader = () => {
  return <>
      <p className={styles.topLabel}>
        <FormattedMessage defaultMessage="For the highest level of support" id="Onboarding.Premium.SmallTitle" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </p>
      <h1 className={styles.title}>
        <FormattedMessage defaultMessage="We recommend {mark}" id="Onboarding.Upsale.Title1" values={{
        mark: <mark>
                <FormattedMessage defaultMessage="Premium" id="Onboarding.Upsale.Title1.Mark" />
              </mark>
      }} data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
        <br />
        <FormattedMessage defaultMessage="so you can get extra guidance" id="Onboarding.Premium.Title2" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </h1>
      <div className={styles.benefitsContainer}>
        {BENEFITS.map(({
        imageUrl,
        text
      }) => {
        return <div className={styles.benefitItem} key={imageUrl}>
              <Image src={imageUrl} alt="" width={32} height={32} />
              <p className={styles.benefitItemText}>{text}</p>
            </div>;
      })}
      </div>
    </>;
};