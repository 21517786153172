import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MoneyBack } from "src/components/common/money-back/money-back";
import { useStores } from "src/components/common/root-store-provider/root-store-provider";
import { NEW_UPSELLS_TEST_NAME, NewUpsellsTestCaseName, OLD_PLAN_CARD_TEST_NAME, OldPlanCardTestCaseName, PLAN_CARD_WITH_CHECKOUT_TEST_NAME, PlanCardWithCheckoutTestCaseName } from "src/constants/analytics";
import { routes } from "src/constants/navigation";
import { TpStripeVariants } from "src/constants/variants";
import { TpPaymentPlansStripeProps, TpStripeSubscription } from "src/features/Stripe/types";
import { getDataFromSessionStorage } from "src/utils";
import { useIsInViewport } from "src/utils/hooks/use-is-in-viewport";
import { tikTokAdvancedMatching, tikTokEvent, twitterEvent, useAnalytics } from "@features/Analytics";
import { PaymentModalStatus, currencyReplacer, useGenerateBraintreeClientToken } from "@features/Payments";
import { PAYMENT_MODAL_QUERY_PARAM } from "@features/Paywall";
import Banner, { BannerType } from "@features/Paywall/components/banner/banner";
import { EmptySubscriptionPlanCardList } from "@features/Paywall/widgets/payment-plans/components/empty-subscription-plan-list";
import { GuaranteeBlock } from "@features/Paywall/widgets/payment-plans/components/guarantee-block";
import { PrivacyAgreement } from "@features/Paywall/widgets/payment-plans/components/privacy-agreement";
import { SubscriptionPlanCardStripe, useGetStripeSubscriptions } from "@features/Stripe";
import { introOfferInfoAtom, noMoneyPlanInfoAtom, premiumYearAllDataAtom, processingModalStatusAtom, selectedPlanAtom, stripePaymentErrorAtom, stripeSubscriptionWithPersistence, stripeWasPaymentTriedAtom, upgradeInfoAtom, upsellInfoAtom, userAtom } from "@features/Stripe/atoms";
import { AutoRenew } from "@features/Stripe/components/AutoRenew";
import { LEARN_MORE_VARIANT, NO_MONEY_LEARN_MORE } from "@features/Stripe/components/LearnMoreList/additionalInfo";
import { BILLING_PERIOD_LTV_MAP } from "@features/Stripe/constants";
import { useGetStripePromise } from "@features/Stripe/hooks";
import { useGetStripeProductId } from "@features/Stripe/hooks/query/use-get-stripe-product-id";
import { StripeModal } from "@features/Stripe/widgets/modal";
import { PaymentDeclinedModal } from "@features/Stripe/widgets/payment-declined-modal";
import PaymentProcessingAlert from "@features/Stripe/widgets/payment-processing-alert/payment-processing-alert";
import { SecondPaymentFailedModal } from "@features/Stripe/widgets/second-payment-failed-modal";
import { SubscriptionPlanCardStripeWithPaymentMethods } from "@features/Stripe/widgets/subscription-plan-card-stripe-with-payment-methods";
import { FormattedMessage } from "@features/intl";
import { ContactBlockEmail3 } from "@features/Paywall/components/contact-block-email3";
import { Guarantees } from "@features/Paywall/components/guarantees";
import { Reviews } from "@features/Paywall/components/reviews";
import { ModalRenderer } from "@features/Stripe/widgets/upsell-modal-renderer";
import { reviews } from "src/components/common/reviews/reviews";
import { UiKitButton } from "src/components/common/ui-kit-button";
import { Policy } from "src/widgets/policy";
import PresentIcon from "../../../../assets/icons/present.svg";
import { useFeature } from "../../../FeatureToggle";
import { SubscriptionPlanCardStripeOld } from "../subscription-plan-card-stripe-old";
import styles from "./payment-plans-stripe.module.scss";
const filteredMap = new Map<string, TpStripeSubscription>();
const REVIEWS_BLOCK_1 = [reviews.user8, reviews.user7, reviews.user9];
export const PaymentPlansStripe = observer((props: TpPaymentPlansStripeProps) => {
  const {
    userProperty,
    variant,
    replaceVariantWithHardCodeTestValue
  } = props;
  const {
    user,
    accessCode
  } = userProperty;
  const stripePromise = useGetStripePromise();
  const [wasCheckoutShownOnMount, setWasCheckoutShownOnMount] = useState(false);
  const [wasNoMoneyPreselected, setWasNoMoneyPreselected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [subscriptionAgreementChecked, setSubscriptionAgreementChecked] = useState(true);
  //todo: выпилить wastried и прочие остатки от onetimeoffer
  const {
    wasTried
  } = useAtomValue(stripeWasPaymentTriedAtom);
  const {
    wasErrorModalWithOfferShown
  } = useAtomValue(stripePaymentErrorAtom);
  const isSubscriptionActive = useAtomValue(stripeSubscriptionWithPersistence);
  const [planAtom, setPlanAtom] = useAtom(selectedPlanAtom);
  const showProcessingModal = useAtomValue(processingModalStatusAtom);
  const setUpsellInfoAtom = useSetAtom(upsellInfoAtom);
  const setPremiumYearAllDataAtom = useSetAtom(premiumYearAllDataAtom);
  const setNoMoneyPlanInfoAtom = useSetAtom(noMoneyPlanInfoAtom);
  const setUpgradeInfoAtom = useSetAtom(upgradeInfoAtom);
  const setIntroOfferInfoAtom = useSetAtom(introOfferInfoAtom);
  const setUserFields = useSetAtom(userAtom);
  const setPaymentError = useSetAtom(stripePaymentErrorAtom);
  const {
    authStore: {
      loginedByWinback,
      campaign
    },
    quizStore: {
      countryCode
    },
    analyticsStore: {
      getCaseNameByTestName
    }
  } = useStores();
  const {
    trackFacebookEvent,
    trackGoogleEvent,
    trackPinterestEvent
  } = useAnalytics();
  const isCheckoutInsidePlanCard = getCaseNameByTestName(PLAN_CARD_WITH_CHECKOUT_TEST_NAME) === PlanCardWithCheckoutTestCaseName.SHOW;
  const isNewUpsellsTest = getCaseNameByTestName(NEW_UPSELLS_TEST_NAME) === NewUpsellsTestCaseName.SHOW;
  const isOldPlanCardTest = getCaseNameByTestName(OLD_PLAN_CARD_TEST_NAME) === OldPlanCardTestCaseName.NEW;
  const isIntroOfferEnabled = useFeature('introOfferFeature');
  const scrollToTheCheckoutSelector = useCallback(() => {
    document.querySelector('#checkout-selector')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }, []);
  const paymentSectionRef = useRef<HTMLDivElement>(null);
  const isPaymentSectionRefInViewport = useIsInViewport(paymentSectionRef);

  // biome-ignore lint/correctness/useExhaustiveDependencies: paymentSectionRef is not changing
  const scrollPaymentSectionIntoView = useCallback(() => {
    if (paymentSectionRef.current) {
      paymentSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [paymentSectionRef]);
  const router = useRouter();
  const {
    query,
    pathname
  } = router;
  const isEmail3Variant = variant === 'email3';
  const {
    payment_intent_client_secret: clientSecretFromUrl,
    redirect_status: redirectStatus,
    stripe_plan_id: selectedPlanIdFromUrl,
    payment_status: paymentStatus
  } = query;
  const isRedirectStatusFailed = !!clientSecretFromUrl && redirectStatus === 'failed' || paymentStatus === 'failure';
  const isRedirectStatusSucceeded = !!clientSecretFromUrl && redirectStatus === 'succeeded';
  const {
    data: productId
  } = useGetStripeProductId({
    variant: (replaceVariantWithHardCodeTestValue as TpStripeVariants)
  });
  const data = useGetStripeSubscriptions({
    productId: productId?.product_id
  });
  useEffect(() => {
    if (data) {
      trackGoogleEvent({
        eventName: 'paywall_plans_loaded',
        options: {
          plans: data?.plans
        }
      });
    }
  }, [data, trackGoogleEvent]);
  const allSubscriptions = useMemo(() => data?.plans && data?.plans?.length > 0 ? [...data.plans].sort((a, b) => a.order - b.order) : [], [data]);
  const filteredSubscriptions = useMemo(() => {
    if (!allSubscriptions) return [];
    for (const item of allSubscriptions) {
      if (wasTried && item.specialOffer || !wasTried && !item.specialOffer && !item.noMoneyDiscount) {
        const combinedItem = {
          ...item,
          learnMoreList: LEARN_MORE_VARIANT[0].learnMoreList,
          hideDescription: true
        };
        if (filteredMap.has(combinedItem.id)) {
          // @ts-ignore
          Object.assign(filteredMap.get(combinedItem.id), combinedItem);
        } else {
          // @ts-ignore item types are inconsistent
          filteredMap.set(combinedItem.id, combinedItem);
        }
      }
    }
    return [...filteredMap.values()];
  }, [allSubscriptions, wasTried]);
  const preselectedSubscriptionId = useMemo(() => filteredSubscriptions.find(item => item.preselected)?.id ?? filteredSubscriptions[0]?.id, [filteredSubscriptions]);
  const upgradeInfo = useMemo(() => {
    if (data == null || data.upgrade_plan == null || Object.keys(data.upgrade_plan).length === 0) {
      return {
        currency: 'usd',
        discountPercentage: 0,
        fullPrice: 0,
        fullPriceDiscount: 0,
        upgradeId: '',
        braintreePlanId: ''
      };
    }
    return data.upgrade_plan;
  }, [data]);
  const upsellInfo = useMemo(() => {
    if (data == null || data.upsell_data == null) {
      return {
        currency: 'usd',
        discountPercentage: 0,
        fullPrice: 0,
        fullPriceDiscount: 0
      };
    }
    return data.upsell_data;
  }, [data]);
  const premiumYearAllDataInfo = useMemo(() => {
    if (data == null || data.premium_year_data == null || data.premium_year_discount_data == null) {
      return {
        premiumYearData: {
          currency: 'usd',
          fullPriceDiscount: 0,
          fullPrice: 0,
          discountPercentage: 0,
          braintreePlanId: '',
          upgradeId: ''
        },
        premiumYearDiscountData: {
          currency: 'usd',
          fullPriceDiscount: 0,
          fullPrice: 0,
          discountPercentage: 0,
          braintreePlanId: '',
          upgradeId: ''
        }
      };
    }
    return {
      premiumYearData: data.premium_year_data,
      premiumYearDiscountData: data.premium_year_discount_data
    };
  }, [data]);
  const noMoneyPlanInfo = useMemo(() => {
    const defaultPlanInfo = {
      currency: 'usd',
      discountPercentage: 0,
      fullPrice: 0,
      fullPriceDiscount: 0,
      priceFromDefaultOneMonth: 0
    };
    if (!data || !data.plans || data.plans.length === 0) {
      return defaultPlanInfo;
    }
    for (const item of data.plans) {
      if (item.noMoneyDiscount) {
        return item;
      }
    }
    return defaultPlanInfo;
  }, [data]);
  const oneMonthPlan = useMemo(() => allSubscriptions.find(item => item.billingPeriod === 1 && item.billingPeriodUnit === 'month' && !item.specialOffer && !item.noMoneyDiscount), [allSubscriptions]);
  const introOfferInfo = useMemo(() => {
    if (data == null || data.intro_offer_data == null) {
      return {
        currency: 'usd',
        fullPriceDiscount: 0,
        introOfferDuration: 0,
        introOfferId: '',
        planId: '',
        billingPeriodUnit: '',
        billingPeriod: 0,
        surcharge: 0
      };
    }
    return data.intro_offer_data;
  }, [data]);
  const analyticsCombinedParameters = useMemo(() => planAtom.priceId != '' ? {
    value: planAtom.fullPriceDiscount ? planAtom.fullPriceDiscount.toFixed(2) : planAtom.fullPrice.toFixed(2),
    currency: planAtom?.currency
  } : {}, [planAtom]);
  const setPaymentInfoAtoms = useCallback(() => {
    setUpgradeInfoAtom(previous => ({
      ...previous,
      ...upgradeInfo
    }));
    setUpsellInfoAtom(previous => ({
      ...previous,
      ...upsellInfo
    }));
    setPremiumYearAllDataAtom(previous => ({
      ...previous,
      ...premiumYearAllDataInfo
    }));
    setNoMoneyPlanInfoAtom(previous => ({
      ...previous,
      ...noMoneyPlanInfo,
      priceFromDefaultOneMonth: oneMonthPlan?.fullPriceDiscount ?? 19.99
    }));
    setIntroOfferInfoAtom(previous => ({
      ...previous,
      ...introOfferInfo
    }));
  }, [introOfferInfo, noMoneyPlanInfo, oneMonthPlan?.fullPriceDiscount, setIntroOfferInfoAtom, setNoMoneyPlanInfoAtom, setUpgradeInfoAtom, setUpsellInfoAtom, setPremiumYearAllDataAtom, upgradeInfo, upsellInfo, premiumYearAllDataInfo]);
  useEffect(() => {
    if (data != null) {
      setPaymentInfoAtoms();
    }
  }, [data, setPaymentInfoAtoms]);
  const openPaymentModalHandler = useCallback(() => {
    setShowModal(true);
    // noinspection JSIgnoredPromiseFromCall
    router.push({
      pathname,
      query: {
        ...query,
        [PAYMENT_MODAL_QUERY_PARAM]: PaymentModalStatus.SHOW,
        ['plan']: planAtom.priceId
      }
    }, undefined, {
      shallow: true
    });
  }, [pathname, planAtom.priceId, query, router]);
  const closePaymentModalHandler = useCallback(() => {
    router.replace({
      pathname: routes.newPlanPaywall,
      query: {
        ...query,
        ['redirect_status']: undefined,
        ['payment_status']: undefined
      }
    }).then(r => r);
    setShowModal(false);
    setTimeout(() => scrollPaymentSectionIntoView(), 500);
  }, [query, router, scrollPaymentSectionIntoView]);
  const handlePlanSelect = useCallback(() => {
    openPaymentModalHandler();
    if (isCheckoutInsidePlanCard) {
      scrollToTheCheckoutSelector();
      trackFacebookEvent({
        eventName: 'InitiateCheckout',
        options: {
          email: user?.email
        }
      });
      trackGoogleEvent({
        eventName: 'InitiateCheckout',
        options: {
          action: 'initiate_checkout',
          category: 'get_plan',
          label: 'InitiateCheckout',
          value: planAtom.fullPriceDiscount
        }
      });
      user?.email ? tikTokAdvancedMatching(user.email) : null;
      tikTokEvent('InitiateCheckout');
    }
    trackGoogleEvent({
      eventName: 'add_to_cart_chosen',
      options: {
        product_id: planAtom.priceId
      }
    });
  }, [isCheckoutInsidePlanCard, openPaymentModalHandler, planAtom.fullPriceDiscount, planAtom.priceId, scrollToTheCheckoutSelector, trackFacebookEvent, trackGoogleEvent, user.email]);
  const handleSetSelectedPlanId = useCallback((planId: string) => {
    //eslint-disable-next-line unicorn/prefer-array-find
    const selectedPlan = allSubscriptions.filter(plan => plan.id === planId)[0];
    setPlanAtom({
      currency: selectedPlan?.currency ?? 'usd',
      planName: selectedPlan?.name ?? '',
      discountPercentage: selectedPlan?.discountPercentage ?? 0,
      specialDiscountPercentage: selectedPlan?.specialDiscountPercentage ?? 0,
      fullPrice: selectedPlan?.fullPrice ?? 0,
      fullPriceDiscount: selectedPlan?.fullPriceDiscount ?? 0,
      billingPeriod: selectedPlan?.billingPeriod ?? 0,
      billingPeriodUnit: selectedPlan?.billingPeriodUnit ?? '',
      priceId: selectedPlan?.id ?? '',
      introOffer: selectedPlan?.introOffer ?? '',
      braintreeMerchantId: selectedPlan?.braintreeMerchantId ?? undefined,
      braintreePlanId: selectedPlan?.braintreePlanId ?? undefined,
      specialOffer: selectedPlan?.specialOffer ?? false,
      introOfferData: {
        ...selectedPlan?.introOfferData,
        fullPrice: Number(selectedPlan?.introOfferData?.fullPrice).toFixed(2) ?? '0',
        fullPriceDiscount: Number(selectedPlan?.introOfferData?.fullPriceDiscount).toFixed(2) ?? '0'
      },
      premiumPriceData: selectedPlan?.premiumPriceData,
      premiumDiscountPriceData: selectedPlan?.premiumDiscountPriceData
    });
  }, [allSubscriptions, setPlanAtom]);
  const selectPlanHandler = useCallback((id: string) => {
    trackGoogleEvent({
      eventName: 'initiate_checkout_another_plan'
    });
    !isPaymentSectionRefInViewport && scrollPaymentSectionIntoView();
    handleSetSelectedPlanId(id);
  }, [handleSetSelectedPlanId, isPaymentSectionRefInViewport, scrollPaymentSectionIntoView, trackGoogleEvent]);
  const userPaid = useMemo(() => isRedirectStatusSucceeded || isSubscriptionActive === 'subscriptionActive' || user.isSubscriptionActive, [isRedirectStatusSucceeded, isSubscriptionActive, user.isSubscriptionActive]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: subscriptionAgreementChecked is not changing
  const handleCheck = useCallback(() => {
    setSubscriptionAgreementChecked(!subscriptionAgreementChecked);
  }, [setSubscriptionAgreementChecked, subscriptionAgreementChecked]);
  useEffect(() => {
    trackFacebookEvent({
      eventName: 'AddToCart',
      options: {
        email: user?.email
      }
    });
    trackGoogleEvent({
      eventName: 'add_to_cart_open',
      options: {
        variant: variant
      }
    });
    user?.email ? tikTokAdvancedMatching(user.email) : null;
    tikTokEvent('AddToCart');
    setUserFields(previous => ({
      ...previous,
      email: user.email ?? 'undefined',
      token: user.token ?? 'undefined',
      campaign: campaign ?? 'undefined',
      userId: user.id ?? 0,
      accessCode: accessCode ?? 'undefined'
    }));
  }, [campaign, setUserFields, accessCode, trackFacebookEvent, trackGoogleEvent, user, variant]);
  const noMoneyPlan = useMemo(() => allSubscriptions.find(item => item.noMoneyDiscount), [allSubscriptions]);
  useEffect(() => {
    if (planAtom.priceId != '' && !planAtom.specialOffer && wasTried) {
      handleSetSelectedPlanId(allSubscriptions.filter(item => item.name === planAtom.planName && item.specialOffer)[0]?.id);
    }
    if (selectedPlanIdFromUrl && !planAtom.priceId) {
      handleSetSelectedPlanId((selectedPlanIdFromUrl as string));
    }
    if (preselectedSubscriptionId && !selectedPlanIdFromUrl && !planAtom.priceId) {
      handleSetSelectedPlanId(preselectedSubscriptionId);
    }
    if (wasErrorModalWithOfferShown && noMoneyPlan && oneMonthPlan && !wasNoMoneyPreselected) {
      setWasNoMoneyPreselected(true);
      handleSetSelectedPlanId(noMoneyPlan.id);
    }
  }, [allSubscriptions, handleSetSelectedPlanId, noMoneyPlan, oneMonthPlan, planAtom.planName, planAtom.priceId, planAtom.specialOffer, preselectedSubscriptionId, selectedPlanIdFromUrl, wasErrorModalWithOfferShown, wasNoMoneyPreselected, wasTried]);
  useEffect(() => {
    if (isRedirectStatusSucceeded && planAtom.priceId != '') {
      const calculateLTV = BILLING_PERIOD_LTV_MAP[planAtom.billingPeriod] || undefined;
      trackFacebookEvent({
        eventName: 'Purchase',
        customData: {
          variant: getDataFromSessionStorage({
            store: 'authStore',
            key: 'variant'
          }),
          paymentMethod: 'paypal',
          paymentSystem: 'stripe'
        },
        options: {
          email: user.email,
          subscription_id: undefined,
          stripe_subscription_id: planAtom.priceId,
          predicted_ltv: variant === 'variant18' ? calculateLTV : 80,
          ...analyticsCombinedParameters
        }
      });
      trackFacebookEvent({
        eventName: 'Subscribe',
        options: {
          email: user.email
        }
      });
      twitterEvent('tw-oehe8-oehej', {
        email: user.email,
        ...analyticsCombinedParameters
      });
      trackPinterestEvent({
        eventName: 'Checkout',
        options: analyticsCombinedParameters
      });
      trackGoogleEvent({
        eventName: 'purchase_success',
        options: {
          product_id: planAtom?.planName,
          payment_type: 'paypal',
          campaign_name_manual: getDataFromSessionStorage({
            store: 'authStore',
            key: 'campaign'
          }) ?? 'undefined',
          email: user.email,
          ...analyticsCombinedParameters
        }
      });
      tikTokAdvancedMatching(user.email);
      tikTokEvent('CompletePayment', {
        content_id: planAtom?.planName,
        quantity: 1,
        ...analyticsCombinedParameters
      });
    }
    if (isRedirectStatusFailed) {
      console.log('isRedirectStatusFailed');
      setPaymentError(previous => ({
        ...previous,
        errorCode: 'insufficient_funds',
        paymentSystem: 'PayPal'
      }));
      trackGoogleEvent({
        eventName: 'purchase_subscription_failed',
        options: {
          payment_type: 'paypal',
          campaign_name_manual: getDataFromSessionStorage({
            store: 'authStore',
            key: 'campaign'
          }) ?? 'undefined',
          product_id: planAtom?.planName ?? '',
          ...analyticsCombinedParameters
        }
      });
    }
  }, [analyticsCombinedParameters, isRedirectStatusFailed, isRedirectStatusSucceeded, planAtom, setPaymentError, trackFacebookEvent, trackGoogleEvent, trackPinterestEvent, user.email, variant]);
  useGenerateBraintreeClientToken({
    userFields: {
      email: user.email,
      id: user.id
    },
    merchantAccountId: planAtom?.braintreeMerchantId
  });
  useEffect(() => {
    if (variant === 'email2' && allSubscriptions.length > 0 && !wasCheckoutShownOnMount) {
      handleSetSelectedPlanId(allSubscriptions[0].id);
      !(isSubscriptionActive === 'subscriptionActive' || user.isSubscriptionActive) && openPaymentModalHandler();
      setWasCheckoutShownOnMount(true);
    }
  }, [allSubscriptions, handleSetSelectedPlanId, isSubscriptionActive, openPaymentModalHandler, user.isSubscriptionActive, variant, wasCheckoutShownOnMount]);
  const renderPlans = useCallback(() => {
    if (allSubscriptions.length === 0 || isCheckoutInsidePlanCard && stripePromise == null) {
      return <EmptySubscriptionPlanCardList />;
    }
    if (wasErrorModalWithOfferShown && noMoneyPlan) {
      if (isCheckoutInsidePlanCard) {
        return <>
              <SubscriptionPlanCardStripeWithPaymentMethods planCardClassname={styles.declineDiscount} stripePromise={stripePromise} plan={noMoneyPlan} learnMoreList={NO_MONEY_LEARN_MORE} submitButtonText={`Claim ${currencyReplacer(noMoneyPlan.currency)}${noMoneyPlan.fullPriceDiscount} Offer`} countryCode={countryCode} wasPaymentTried={wasTried} isCardSelected={planAtom.priceId === noMoneyPlan.id} onSelectPlan={selectPlanHandler} handlePlanSelect={handlePlanSelect} getPlanDisabled={!subscriptionAgreementChecked || allSubscriptions.length === 0} />
              {oneMonthPlan && <SubscriptionPlanCardStripeWithPaymentMethods plan={oneMonthPlan} stripePromise={stripePromise} learnMoreList={LEARN_MORE_VARIANT.find(item => item.name === '1-MONTH')?.learnMoreList ?? LEARN_MORE_VARIANT[0].learnMoreList} countryCode={countryCode} wasPaymentTried={wasTried} isCardSelected={planAtom.priceId === oneMonthPlan.id} onSelectPlan={selectPlanHandler} handlePlanSelect={handlePlanSelect} getPlanDisabled={!subscriptionAgreementChecked || allSubscriptions.length === 0} />}
            </>;
      }
      return <>
            <SubscriptionPlanCardStripe planCardClassname={styles.declineDiscount} plan={noMoneyPlan} learnMoreList={NO_MONEY_LEARN_MORE} submitButtonText={`Claim ${currencyReplacer(noMoneyPlan.currency)}${noMoneyPlan.fullPriceDiscount} Offer`} countryCode={countryCode} wasPaymentTried={wasTried} isCardSelected={planAtom.priceId === noMoneyPlan.id} onSelectPlan={selectPlanHandler} handlePlanSelect={handlePlanSelect} getPlanDisabled={!subscriptionAgreementChecked || allSubscriptions.length === 0} />
            {oneMonthPlan && <SubscriptionPlanCardStripe plan={oneMonthPlan} learnMoreList={LEARN_MORE_VARIANT.find(item => item.name === '1-MONTH')?.learnMoreList ?? LEARN_MORE_VARIANT[0].learnMoreList} countryCode={countryCode} wasPaymentTried={wasTried} isCardSelected={planAtom.priceId === oneMonthPlan.id} onSelectPlan={selectPlanHandler} handlePlanSelect={handlePlanSelect} getPlanDisabled={!subscriptionAgreementChecked || allSubscriptions.length === 0} />}
          </>;
    }
    if (isOldPlanCardTest) {
      return <>
            {filteredSubscriptions.map(item => <SubscriptionPlanCardStripeOld key={item.id} plan={item} countryCode={countryCode} wasPaymentTried={wasTried} isCardSelected={planAtom.priceId === item.id} onSelectPlan={selectPlanHandler} handlePlanSelect={handlePlanSelect} getPlanDisabled={!subscriptionAgreementChecked || allSubscriptions.length === 0} />)}
          </>;
    }
    if (variant === 'email2') {
      return <SubscriptionPlanCardStripe planCardClassname={styles.declineDiscount} plan={allSubscriptions[0]} learnMoreList={NO_MONEY_LEARN_MORE} submitButtonText={`Claim ${currencyReplacer(allSubscriptions[0].currency)}${allSubscriptions[0].fullPriceDiscount} Offer`} countryCode={countryCode} wasPaymentTried={wasTried} isCardSelected={planAtom.priceId === allSubscriptions[0].id} onSelectPlan={selectPlanHandler} handlePlanSelect={handlePlanSelect} getPlanDisabled={!subscriptionAgreementChecked || allSubscriptions.length === 0} />;
    }
    if (isCheckoutInsidePlanCard) {
      return <>
            {filteredSubscriptions.map(item => <SubscriptionPlanCardStripeWithPaymentMethods stripePromise={stripePromise} key={item.id} plan={item} countryCode={countryCode} wasPaymentTried={wasTried} isCardSelected={planAtom.priceId === item.id} onSelectPlan={selectPlanHandler} handlePlanSelect={handlePlanSelect} getPlanDisabled={!subscriptionAgreementChecked || allSubscriptions.length === 0} />)}
          </>;
    }
    return <>
          {filteredSubscriptions.map(item => <SubscriptionPlanCardStripe key={item.id} plan={item} countryCode={countryCode} wasPaymentTried={wasTried} isCardSelected={planAtom.priceId === item.id} onSelectPlan={selectPlanHandler} handlePlanSelect={handlePlanSelect} getPlanDisabled={!subscriptionAgreementChecked || allSubscriptions.length === 0} />)}
        </>;
  }, [allSubscriptions, countryCode, filteredSubscriptions, handlePlanSelect, isCheckoutInsidePlanCard, noMoneyPlan, oneMonthPlan, planAtom.priceId, selectPlanHandler, stripePromise, subscriptionAgreementChecked, variant, wasErrorModalWithOfferShown, wasTried, isOldPlanCardTest]);
  const renderGetMyPlan = useMemo(() => {
    return <UiKitButton className={styles.buttonEmail3} fullWidth disabled={!subscriptionAgreementChecked} onClick={handlePlanSelect} data-testid="get-my-plan-email3">
          <FormattedMessage defaultMessage="GET MY PLAN" id="Onboarding.Paywall.Main.Timer.Button" />
        </UiKitButton>;
  }, [handlePlanSelect, subscriptionAgreementChecked]);
  return <>
        <h3 className={styles.titleSection}>
          {wasErrorModalWithOfferShown || variant === 'email2' ? 'Save money and see amazing results' : <FormattedMessage id="Onboarding.Paywall.Main.Title.Plans" defaultMessage="Choose your plan" />}
        </h3>

        {wasErrorModalWithOfferShown || variant === 'email2' ? null : wasTried ? <div className={styles.extraDiscount}>
            <PresentIcon />
            <p>
              <FormattedMessage id="Onboarding.SecondPaywall.ExtraDiscount" defaultMessage="Get an extra discount on your personal plan" />
            </p>
          </div> : <Banner variant={BannerType.RED} />}
        <div ref={paymentSectionRef}>
          <ul>{renderPlans()}</ul>

          {!isEmail3Variant && <MoneyBack />}
          {isOldPlanCardTest && <UiKitButton className={styles.oldGetMyPlanButton} fullWidth disabled={!subscriptionAgreementChecked} onClick={handlePlanSelect} data-testid="get-my-plan-email3">
              <FormattedMessage defaultMessage="GET MY PLAN" id="Onboarding.Paywall.Main.Timer.Button" />
            </UiKitButton>}
        </div>

        {!isEmail3Variant && <>
            <GuaranteeBlock />
            {isIntroOfferEnabled ? <AutoRenew /> : <PrivacyAgreement isChecked={subscriptionAgreementChecked} onChecked={handleCheck} />}
          </>}

        {!isCheckoutInsidePlanCard && <StripeModal show={showModal} onClose={closePaymentModalHandler} />}
        {noMoneyPlanInfo.fullPrice > 0 && <>
            <PaymentDeclinedModal onClose={closePaymentModalHandler} />
            <SecondPaymentFailedModal />
          </>}
        <PaymentProcessingAlert show={showProcessingModal} />
        <ModalRenderer isNewUpsellsTest={isNewUpsellsTest} isIntroOfferEnabled={isIntroOfferEnabled === true} isUserPaid={userPaid} isUserLoginedByWinback={loginedByWinback} />

        {variant === 'email3' && <>
            <section className={styles.reviews}>
              <Reviews reviews={REVIEWS_BLOCK_1} />
            </section>
            {renderGetMyPlan}
            <section>
              <Guarantees />
            </section>
            {renderGetMyPlan}

            <div className={styles.policyWrapper}>
              <Policy className={styles.policy} place="paywall" />
            </div>
            <ContactBlockEmail3 />
          </>}
      </>;
});
PaymentPlansStripe.displayName = 'PaymentPlansStripe';