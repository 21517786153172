import { axiosMicroservice } from 'src/api/axios';
import type { TpStripeSubscriptionResponse } from 'src/features/Stripe/types';

export const getStripeSubscriptions = async ({
  productId,
  app = 'FaceYoga',
}: {
  productId: string | undefined;
  app?: string;
}): Promise<TpStripeSubscriptionResponse> => {
  const { data } = await axiosMicroservice.get(
    `stripe/get_plans?app=${app}&product_id=${productId}`,
  );
  return data;
};
