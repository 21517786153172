import { UpsellSharedModalProps } from '@features/Stripe/widgets/upsell-modal-renderer';
import { memo, useCallback, useEffect } from 'react';
import { Image } from 'src/components/common/Image';
import Modal from 'src/components/common/modal/modal/modal';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { VIDEO_SOURCE } from 'src/utils/constants';
import { useAnalytics } from '@features/Analytics';
import { currencyReplacer } from '@features/Payments';
import { upsellInfoAtom } from '@features/Stripe/atoms';
import { FormattedMessage } from '@features/intl';
import { useAtomValue } from 'jotai/index';
import { replaceDecimalsWithNines } from 'src/utils/replace-decimals-with-nines';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';
import styles from './upsell-scanner.module.scss';
const FEATURE_LIST = [{
  imagePath: 'tube-emoji.webp',
  text: <FormattedMessage defaultMessage="Instant analysis of beauty products" id="Onboarding.Scanner.Option1" />
}, {
  imagePath: 'shampoo-emoji.webp',
  text: <FormattedMessage defaultMessage="Know exactly what's in your {br} medication" id="Onboarding.Scanner.Option2" values={{
    br: <br />
  }} />
}, {
  imagePath: 'magnifying-glass-emoji.webp',
  text: <FormattedMessage defaultMessage="Get insights on safety and {br} certification" id="Onboarding.Scanner.Option3" values={{
    br: <br />
  }} />
}, {
  imagePath: 'papers-emoji.webp',
  text: <FormattedMessage defaultMessage="Understand the purpose of every {br} ingredient" id="Onboarding.Scanner.Option4" values={{
    br: <br />
  }} />
}];
export const UpsellScanner = memo(({
  onNext
}: UpsellSharedModalProps) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    fullPriceDiscount,
    currency
  } = useAtomValue(upsellInfoAtom);
  const scannerPrice = replaceDecimalsWithNines(fullPriceDiscount * 4);
  const handleBuy = useCallback(() => {
    trackGoogleEvent({
      eventName: 'ai-scanner_button_add-to-plan_click'
    });
    onNext('UPSELL_EXTRA_DISCOUNT_PREMIUM');
  }, [onNext, trackGoogleEvent]);
  useEffect(() => {
    trackGoogleEvent({
      eventName: 'ai-scanner_screen__loaded'
    });
  }, [trackGoogleEvent]);
  return <Modal modalContentClassName={styles.container} show={true} canClose={false}>
      <div className={styles.innerContainer}>
        <video autoPlay playsInline muted loop className={styles.video}>
          <source src={`${VIDEO_SOURCE}scanner-slider.mp4`} type="video/mp4" />
        </video>

        <div className={styles.limitedLabel}>
          <Image src="fire-emoji.webp" width={16} height={16} alt="" />
          <FormattedMessage defaultMessage="Available on this screen only" id="Onboarding.Scanner.Bar" />
        </div>

        <div className={styles.priceBlock}>
          <Image className={styles.productImage} src="skanner-product-image.webp" width={117.7} height={117.7} alt="" />

          <div className={styles.aiScannerTitleContainer}>
            <Image src="ai-scanner-emoji.webp" width={24} height={24} alt="" />
            <p>
              <FormattedMessage defaultMessage="AI Skincare Scanner" id="Onboarding.Scanner.Title" />
            </p>
          </div>

          <div className={styles.priceInfo}>
            <p className={styles.currentPriceLabel}>
              <FormattedMessage defaultMessage="FREE" id="Onboarding.Scanner.Free" />
            </p>
            <del className={styles.oldPrice}>
              {currencyReplacer(currency)}
              {Number(scannerPrice).toFixed(2)}
            </del>
          </div>

          <ul className={styles.featureList}>
            {FEATURE_LIST.map(({
            imagePath,
            text
          }) => <li key={imagePath}>
                <Image src={imagePath} width={32} height={32} alt="" />
                {text}
              </li>)}
          </ul>
        </div>

        <div className={styles.descriptionBlock}>
          <p>
            <FormattedMessage id="Onboarding.Premium.Terms1" defaultMessage="By purchasing, you agree to our {TOS} {PrivacyPolicy} {RefundPolicy1} {RefundPolicy2}" values={{
            TOS: <TermsLink place="upsale">
                    <FormattedMessage defaultMessage="Terms of Service," id="Onboarding.Premium.Terms1.TOS" />
                  </TermsLink>,
            PrivacyPolicy: <PolicyLink place="upsale">
                    <FormattedMessage defaultMessage="Privacy Policy," id="Onboarding.Premium.Terms1.PrivacyPolicy" />
                  </PolicyLink>,
            RefundPolicy1: <RefundLink place="upsale">
                    <FormattedMessage defaultMessage="Money-Back Guarantee," id="Onboarding.Premium.Terms1.RefundPolicy1" />
                  </RefundLink>,
            RefundPolicy2: <RefundLink place="upsale">
                    <FormattedMessage defaultMessage="Refund and Cancellation Policy." id="Onboarding.Premium.Terms1.RefundPolicy2" />
                  </RefundLink>
          }} />
          </p>

          <p>
            <FormattedMessage defaultMessage="Mimika will use your payment details to seamlessly {br} process future payments. All transactions will be {br} converted into USD based on your bank’s exchange rate {br} at the time of payment. This is a one-time payment offer {br} with lifetime access. This purchase cannot be refunded. {br} If you cannot get the access to the eBook, please email {br}our support team at support@mimika-app.com. You may {br} also want to take a screenshot of this information for {br} your reference." id="Onboarding.Scanner.Terms2" values={{
            br: <br />
          }} />
          </p>
        </div>

        <div className={styles.buttonContainer}>
          <UiKitButton data-testid="scanner-continue-button" fullWidth className={styles.payButton} onClick={handleBuy}>
            <FormattedMessage defaultMessage="Add to plan" id="Onboarding.Scanner.Button" />{' '}
            <mark>
              <FormattedMessage defaultMessage="for FREEE" id="Onboarding.Scanner.ButtonFree" />
            </mark>
          </UiKitButton>
        </div>
      </div>
    </Modal>;
});
UpsellScanner.displayName = 'UpsellScanner';