import { PrivacyAgreement } from '@features/Paywall/widgets/payment-plans/components/privacy-agreement';
import { NowAndLaterPremiumPrices } from '@features/Upsell/components/now-and-later-premium-prices';
import { PremiumHeader } from '@features/Upsell/components/premium-header';
import { PremiumPlansCompareSection } from '@features/Upsell/components/premium-plans-compare-section';
import { UpsellAgreeAndDeclineButtons } from '@features/Upsell/components/upsell-agree-and-decline-buttons';
import { UpsellPolicy } from '@features/Upsell/components/upsell-policy';
import classNames from 'classnames';
import { memo, useCallback, useEffect, useState } from 'react';
import { Image } from 'src/components/common/Image';
import { SimpleImage } from 'src/components/common/SimpleImage';
import CircleButton from 'src/components/common/circle-button/circle-button';
import CloseButton from 'src/components/common/close-button/close-button';
import Modal from 'src/components/common/modal/modal/modal';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';
import { useAnalytics } from '@features/Analytics';
import { currencyReplacer } from '@features/Payments';
import { selectedPlanAtom, upsellInfoAtom, userAtom } from '@features/Stripe/atoms';
import { useModifySubscription } from '@features/Stripe/hooks/mutation/use-modify-subscription';
import { UpsellSharedModalProps } from '@features/Stripe/widgets/upsell-modal-renderer';
import { UpsellLoader } from '@features/Upsell/components/upsell-loader';
import { FormattedMessage } from '@features/intl';
import { useAtomValue } from 'jotai/index';
import { replaceDecimalsWithNines } from 'src/utils/replace-decimals-with-nines';
import styles from './upsell-extra-discount-premium.module.scss';
export const UpsellExtraDiscountPremium = memo(({
  onNext
}: UpsellSharedModalProps) => {
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(true);
  const [includeOffer, setIncludeOffer] = useState(true);
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    mutateAsync: modifySubscription
  } = useModifySubscription();
  const {
    fullPriceDiscount: ebookPrice
  } = useAtomValue(upsellInfoAtom);
  const {
    currency,
    fullPriceDiscount,
    premiumDiscountPriceData,
    billingPeriodUnit
  } = useAtomValue(selectedPlanAtom);
  const {
    email,
    userId
  } = useAtomValue(userAtom);
  const scannerPrice = replaceDecimalsWithNines(ebookPrice * 4);
  const handleDecline = useCallback(() => {
    trackGoogleEvent({
      eventName: 'we-recommended_button_decline_click'
    });
    onNext('UPSELL_YEARLY_PREMIUM');
  }, [onNext, trackGoogleEvent]);
  const handleBuy = useCallback(() => {
    trackGoogleEvent({
      eventName: 'we-recommended_button_try-premium_click'
    });
    if (premiumDiscountPriceData?.upgradeId) {
      modifySubscription({
        email,
        userId,
        newPlanId: premiumDiscountPriceData?.upgradeId
      }).then(r => r);
      onNext('UPSELL_YEARLY_PREMIUM');
    }
  }, [onNext, modifySubscription, email, userId, premiumDiscountPriceData?.upgradeId, trackGoogleEvent]);
  useEffect(() => {
    trackGoogleEvent({
      eventName: 'we-recommended_screen__loaded'
    });
  }, [trackGoogleEvent]);
  return <Modal modalContentClassName={styles.container} show={true} canClose={false}>
        {!premiumDiscountPriceData ? <UpsellLoader /> : <div className={styles.innerContainer}>
            <PremiumHeader />

            <div className={classNames(styles.aiScannerOffer, !includeOffer && styles.aiScannerOfferExcluded)}>
              {includeOffer ? <CloseButton className={styles.aiScannerOfferCloseButton} onClick={() => setIncludeOffer(false)} /> : <CircleButton className={styles.aiScannerOfferAddButton} onClick={() => setIncludeOffer(true)}>
                  <SimpleImage width={16} height={16} src="cart-icon.svg" alt="" />
                </CircleButton>}

              <div className={styles.specialDealLabel}>
                <Image src="fire-emoji.webp" width={24} height={24} alt="" />{' '}
                <FormattedMessage defaultMessage="Special deal just for you" id="Onboarding.Premium.Offer.BarYellow" />
                <Image src="fire-emoji.webp" width={24} height={24} alt="" />
              </div>

              <div className={styles.aiScannerOfferContentContainer}>
                <div className={styles.aiScannerOfferTitle}>
                  <Image src="ai-scanner-emoji.webp" width={24} height={24} alt="" />
                  <p>
                    <FormattedMessage defaultMessage="AI Skincare Scanner" id="Onboarding.Premium.Offer.Title" />
                  </p>
                </div>

                <div className={styles.aiScannerOfferPriceInfo}>
                  <p>
                    <FormattedMessage defaultMessage="AI Skincare Scanner" id="Onboarding.Premium.Offer.TitleSmall" />
                  </p>
                  <div>
                    <del>
                      {currencyReplacer(currency)}
                      {scannerPrice.toFixed(2)}
                    </del>
                    <span className={styles.free}>
                      <FormattedMessage defaultMessage="FREE" id="Onboarding.Premium.Offer.Free" />
                    </span>
                  </div>
                </div>

                <p className={styles.aiScannerOfferDescription}>
                  <FormattedMessage defaultMessage="Age confidently and know how your {br} metabolism affects your weight" id="Onboarding.Premium.Offer.Text" values={{
              br: <br />
            }} />
                </p>

                <div className={styles.aiScannerOfferDiscountLabel}>
                  <Image src="wrapped-gift-emoji.webp" width={18} height={18} alt="" />
                  <span>
                    <FormattedMessage defaultMessage="Includes 25% OFF Premium" id="Onboarding.Premium.Offer.BarGreen" />
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.compareSectionWrapper}>
              <PremiumPlansCompareSection currency={currency} currentPrice={fullPriceDiscount} periodUnit={billingPeriodUnit} premiumCurrentPrice={0.0} premiumOldPrice={premiumDiscountPriceData.fullPriceDiscount} trialDays={premiumDiscountPriceData.introOfferData.introOfferDuration} isExtraDiscount />
            </div>

            <NowAndLaterPremiumPrices premiumDayDuration={premiumDiscountPriceData.introOfferData.introOfferDuration} currentPrice={0.0} currency={currency} featurePrice={premiumDiscountPriceData.fullPriceDiscount} />

            <UpsellPolicy />

            <PrivacyAgreement agreementText={<>
                  I agree to our <PolicyLink place="upsale" />{' '}
                  <TermsLink place="upsale" /> and <RefundLink place="upsale" />
                </>} className={styles.privacyAgreement} isChecked={isPrivacyChecked} onChecked={() => setIsPrivacyChecked(!isPrivacyChecked)} />

            <UpsellAgreeAndDeclineButtons testIdBuyButton="premium-extra-buy-button" testIdDeclineButton="premium-extra-decline-button" handleBuy={handleBuy} handleDecline={handleDecline} disabled={!isPrivacyChecked} />
          </div>}
      </Modal>;
});
UpsellExtraDiscountPremium.displayName = 'UpsellExtraDiscountPremium';