export const UPGRADE_FIRST_AB_TEST_NAME = 'Mimika|Upgrade (1st)';
export enum UpgradeFirstAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'Mimika|Upgrade (1st)',
}

export const COOKIES_AB_TEST_NAME = 'Mimika| Cookie';
export enum CookiesAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B Cookie',
}

export const FIRST_SCREEN_LOADER_AB_TEST_NAME = 'Mimika| A/B loader';
export enum FirstScreenLoaderAbTestCaseName {
  DEFAULT = 'A default',
  NEW = 'B A/B loader',
}

export const PLAN_CARD_WITH_CHECKOUT_TEST_NAME = 'Checkout-SAAS';
export enum PlanCardWithCheckoutTestCaseName {
  HIDE = 'A default',
  SHOW = 'B Checkout-SAAS',
}

export const WELCOME_LOADER_TEST_NAME_V2 = 'DEV-863 a/b first screen loader v2';
export enum WelcomeLoaderTestV2CaseName {
  DEFAULT = 'A default',
  SHOW = 'B first screen loader',
}

export const VIDEO_REVIEWS_TEST_NAME = 'video feedback';
export enum VideoReviewsTestCaseName {
  HIDE = 'A default',
  SHOW = 'B video feedback',
}

export const PRESELECTED_SUBSCRIPTION_TEST_NAME =
  'DEV-885 Different Preselected subscription v21';
export enum PreselectedSubscriptionTestCaseName {
  DEFAULT = 'A Default',
  NEW = 'B  Different Preselected subscription',
}

export const SCANNER_TEST_NAME = 'DEV-973 Scanner screens';

export const NEW_UPSELLS_TEST_NAME = 'DEV-966 Upsale Flow';
export enum NewUpsellsTestCaseName {
  HIDE = 'A default',
  SHOW = 'B Upsale flow Stripe',
}

export const PREPAYWALL_SHORT_TEST_NAME = 'DEV 888 pre-paywall';
export enum PrepaywallShortTestCaseName {
  DEFAULT = 'A default Long pre-paywall',
  NEW = 'B Short pre-paywall',
}

export const OLD_PLAN_CARD_TEST_NAME = 'Test old card';
export enum OldPlanCardTestCaseName {
  DEFAULT = 'A Default',
  NEW = 'B old card',
}
