import { axiosMicroservice } from 'src/api/axios';

import { TpStripeGetProductId } from '@features/Stripe/types';

export const getStripeProductId = async ({
  variant,
  app = 'FaceYoga',
}: TpStripeGetProductId): Promise<{ product_id: string }> => {
  const { data } = await axiosMicroservice.get(
    `stripe/products_map?app=${app}&variant=${variant}`,
  );
  return data;
};
