import { TpModalProps } from '@mentalgrowth/ui-kit-web';
import UiKitModal from 'src/components/common/ui-kit-modal';
type TpModalContainerProps = TpModalProps & {
  backdropClassName?: string;
  className?: string;
  dialogClassName?: string;
};
const ModalContainer = ({
  className,
  backdropClassName,
  dialogClassName,
  ...props
}: TpModalContainerProps) => <UiKitModal {...props} data-sentry-element="UiKitModal" data-sentry-component="ModalContainer" data-sentry-source-file="modal-container.tsx" />;
export default ModalContainer;