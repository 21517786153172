import { useQuery } from '@tanstack/react-query';

import { getStripeUnsubscription } from '@features/Stripe/api/getStripeUnsubscriptions';
import { useGetAppName } from 'src/utils/hooks';

export const useGetUnsubscribePlans = ({
  productId,
  countryCode,
}: {
  productId: string | undefined;
  countryCode: string | undefined;
}) => {
  const app = useGetAppName();
  const { data } = useQuery({
    queryKey: ['stripe-subscriptions', productId, app],
    queryFn: () => getStripeUnsubscription({ productId, countryCode, app }),
    enabled: !!productId && !!countryCode && !!app,
  });

  return data;
};
