import classNames from 'classnames';
import { ComponentProps, HTMLProps, ReactNode } from 'react';
import { UiKitLoadingButton } from 'src/components/common/ui-kit-loading-button';
import Modal from '../modal/modal';
import styles from './form-modal.module.scss';
export interface Props extends ComponentProps<typeof Modal>, Pick<HTMLProps<HTMLFormElement>, 'onSubmit'> {
  isValid?: boolean;
  isSubmitting?: boolean;
  submitButtonText?: ReactNode;
  submitButtonVariant?: string;
  className?: string;
  contentClassName?: string;
}
const FormModal = ({
  footer,
  isValid,
  onSubmit,
  isSubmitting,
  submitButtonText,
  submitButtonVariant,
  children,
  className,
  contentClassName,
  ...props
}: Props) => <Modal {...props} data-sentry-element="Modal" data-sentry-component="FormModal" data-sentry-source-file="form-modal.tsx">
    <form className={classNames('modal-dialog', className)} onSubmit={onSubmit}>
      <div className={classNames('modal-content', contentClassName)}>
        {children}
        <div className={styles.bottomContainer}>
          <UiKitLoadingButton type="submit" disabled={!isValid} loading={isSubmitting} fullWidth
        // @ts-ignore
        variant={submitButtonVariant} data-sentry-element="UiKitLoadingButton" data-sentry-source-file="form-modal.tsx">
            {submitButtonText}
          </UiKitLoadingButton>
        </div>
      </div>
    </form>
  </Modal>;
export default FormModal;