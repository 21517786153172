import { useQuery } from '@tanstack/react-query';

import { TpStripeVariants } from 'src/constants/variants';

import { getStripeProductId } from '@features/Stripe/api';
import { useGetAppName } from 'src/utils/hooks';

export const useGetStripeProductId = ({
  variant,
}: {
  variant: TpStripeVariants;
}) => {
  const app = useGetAppName();

  return useQuery({
    queryKey: ['stripe-product-id', variant, app],
    queryFn: () => getStripeProductId({ variant, app }),
    enabled: !!variant && !!app,
  });
};
