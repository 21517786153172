import { Elements } from "@stripe/react-stripe-js";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import Alert from "src/components/common/modal/alert/alert";
import { selectedPlanAtom, userAtom } from "src/features/Stripe/atoms";
import { tikTokAdvancedMatching, tikTokEvent, useAnalytics } from "@features/Analytics";
import { ELEMENTS_APPEARANCE } from "@features/Stripe/constants";
import { useCreateSubscription, useGetStripePromise } from "@features/Stripe/hooks";
import { CheckoutForm } from "@features/Stripe/widgets/checkout-form";
import { CheckDetails } from "@features/Stripe/widgets/modal/components/check-details";
import { FormattedMessage } from "@features/intl";
import PaymentLoader from "../../../../assets/images/payment/payment-loader.svg";
import { useFeature } from "../../../FeatureToggle";
import styles from "./stripe-modal.module.scss";
export const StripeModal = memo((props: {
  show: boolean;
  onClose: () => void;
}) => {
  const {
    show,
    onClose
  } = props;
  const {
    trackFacebookEvent,
    trackGoogleEvent
  } = useAnalytics();
  const stripePromise = useGetStripePromise();
  const [isLoading, setIsLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const {
    billingPeriod,
    billingPeriodUnit,
    currency,
    fullPriceDiscount,
    discountPercentage,
    fullPrice,
    priceId,
    planName,
    braintreePlanId,
    braintreeMerchantId,
    introOfferData
  } = useAtomValue(selectedPlanAtom);
  const {
    email,
    token,
    userId,
    campaign
  } = useAtomValue(userAtom);
  const setCustomerId = useSetAtom(userAtom);
  const isIntroOfferEnabled = useFeature("introOfferFeature");
  const currentPlanDetails = useMemo(() => isIntroOfferEnabled ? {
    fullPrice: introOfferData?.fullPrice,
    fullPriceDiscount: introOfferData?.fullPriceDiscount,
    introOfferId: introOfferData?.introOfferId,
    introOfferDuration: Number(introOfferData?.introOfferDuration),
    billingPeriod: introOfferData?.billingPeriod,
    billingPeriodUnit: introOfferData?.billingPeriodUnit
  } : {
    fullPrice: fullPrice,
    fullPriceDiscount: fullPriceDiscount,
    introOfferId: undefined,
    introOfferDuration: undefined,
    billingPeriod: billingPeriod,
    billingPeriodUnit: billingPeriodUnit
  }, [isIntroOfferEnabled, introOfferData, fullPrice, fullPriceDiscount, billingPeriod, billingPeriodUnit]);
  const {
    mutateAsync: createSubscription
  } = useCreateSubscription();
  const handleLoading = useCallback(() => {
    setIsLoading(false);
  }, []);
  const createCustomerAndSubscription = useCallback(async () => {
    const {
      client_secret,
      customer_id
    } = await createSubscription({
      priceId,
      currency,
      token,
      email,
      userId,
      campaign,
      introOfferId: currentPlanDetails.introOfferId,
      introOfferDuration: currentPlanDetails.introOfferDuration
    });
    setClientSecret(client_secret);
    setCustomerId(previous => ({
      ...previous,
      customerId: customer_id
    }));
  }, [campaign, createSubscription, currency, email, currentPlanDetails.introOfferDuration, currentPlanDetails.introOfferId, priceId, setCustomerId, token, userId]);
  const options = useMemo(() => ({
    clientSecret: clientSecret,
    appearance: ELEMENTS_APPEARANCE
  }), [clientSecret]);
  const closeHandle = useCallback(() => {
    onClose();
    setClientSecret("");
    setIsLoading(true);
    trackGoogleEvent({
      eventName: "initiate_checkout_closed"
    });
  }, [onClose, trackGoogleEvent]);
  useEffect(() => {
    if (show) {
      createCustomerAndSubscription().then(r => r);
      trackFacebookEvent({
        eventName: "InitiateCheckout",
        options: {
          email: email
        }
      });
      trackGoogleEvent({
        eventName: "initiate_checkout"
      });
      tikTokAdvancedMatching(email);
      tikTokEvent("InitiateCheckout");
    }
  }, [createCustomerAndSubscription, email, show, trackFacebookEvent, trackGoogleEvent]);
  useEffect(() => () => {
    setClientSecret("");
    setIsLoading(true);
  }, []);
  useEffect(() => {
    if (!isLoading) {
      trackGoogleEvent({
        eventName: "checkout_methods_loaded"
      });
    }
  }, [isLoading, trackGoogleEvent]);
  return <Alert className={styles.container} titleClassName={styles.modalTitle} show={show} onHide={closeHandle}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            <FormattedMessage defaultMessage="Select your payment method" id="Onboarding.Checkout.Title" />
          </h1>
          <div className={classNames({
        [styles.hidePayments]: isLoading
      })}>
            <CheckDetails currency={currency} fullPriceDiscount={currentPlanDetails.fullPriceDiscount} billingPeriod={currentPlanDetails.billingPeriod} fullPrice={currentPlanDetails.fullPrice} discountPercentage={discountPercentage} billingPeriodUnit={currentPlanDetails.billingPeriodUnit} />
            <div className={styles.placeholder}>
              {clientSecret && stripePromise && <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm handleLoading={handleLoading} clientSecret={clientSecret} userEmail={email} showIntroOfferDescription={isIntroOfferEnabled === true} planInfo={{
              planName,
              fullPriceDiscount: Number(currentPlanDetails.fullPriceDiscount),
              currency,
              priceId,
              billingPeriod,
              billingPeriodUnit,
              braintreePlanId,
              braintreeMerchantId
            }} />
                </Elements>}
            </div>
          </div>
          {isLoading && <div className={styles.loaderContainer}>
              <PaymentLoader className={styles.loader} />
              <FormattedMessage defaultMessage="Loading payment options…" id="Onboarding.Payment.Loader.Text" />
            </div>}
        </div>
      </Alert>;
});
StripeModal.displayName = 'StripeModal';