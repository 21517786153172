import classNames from "classnames";
import { TpCardPlanChildrenProps } from "@features/Paywall/types";
import { useFeature } from "../../../../FeatureToggle";
import styles from "./price-unit-full.module.scss";
const formatPrice = (price: number) => {
  if (Number.isInteger(price)) {
    return price.toFixed(2);
  }
  return price;
};
export const PriceUnitFull = (props: TpCardPlanChildrenProps) => {
  const {
    plan,
    reverse,
    checked,
    className
  } = props;
  //TODO: Delete after cleanup tests
  const isIntroOfferEnabled = useFeature('introOfferFeature');
  if (!plan) {
    return null;
  }
  const {
    //@ts-expect-error stripe fields
    initialPrice,
    fullPrice,
    //@ts-expect-error stripe fields
    trialPeriodDays,
    fractionalPrice,
    currency,
    introOfferData
  } = plan;
  let priceUnitFull: string;
  if (initialPrice != null) {
    priceUnitFull = `${currency} ${(fullPrice / trialPeriodDays).toFixed(2)}`;
  } else {
    priceUnitFull = isIntroOfferEnabled ? `${currency} ${formatPrice((introOfferData?.fractionalPrice as number))}` : `${currency} ${formatPrice(fractionalPrice)}`;
  }
  const priceUnitFullReversed = initialPrice != null ? `${(fullPrice / trialPeriodDays).toFixed(2)} ${currency}` : `${formatPrice(fractionalPrice)} ${currency}`;
  return <del className={classNames(styles.priceUnitFull, {
    [styles.priceUnitFullChecked]: checked
  }, className)} data-sentry-component="PriceUnitFull" data-sentry-source-file="index.tsx">
      {reverse ? priceUnitFullReversed : priceUnitFull}
    </del>;
};