import { observer } from 'mobx-react-lite';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { isDevelopment } from 'src/utils';
import { Clarity, GoogleAnalyticsComponent, PinterestPixel, TikTokPixel, TwitterPixel } from '@features/Analytics';
import { DebugUi } from '@features/Analytics/components/DebugUi';
import { useShouldInit } from '@features/Analytics/hooks';
import { GoogleTagManager } from '@features/Analytics/components/GoogleAnalytics';
import { GTM_ID } from '@features/Analytics/constants';
import { useExternalId } from '@features/Analytics/hooks/useExernalId';
import FbPixel from '../FacebookPixel';
export const AnalyticsInject = observer(() => {
  const {
    authStore: {
      variant
    }
  } = useStores();
  const {
    shouldPixelInit,
    shouldPTInit,
    shouldTwitterInit,
    shouldTikTokInit,
    shouldGoogleAnalyticsV2Init,
    shouldGoogleAnalyticsInit,
    shouldClarityInit,
    shouldClarityV2Init
  } = useShouldInit(variant);
  const externalId = useExternalId();
  return <>
      {shouldPixelInit && externalId && <FbPixel externalId={externalId} />}
      {shouldGoogleAnalyticsV2Init && GTM_ID && <GoogleTagManager />}
      {shouldGoogleAnalyticsInit && externalId && <GoogleAnalyticsComponent externalId={externalId} shouldSecondGaInit={shouldGoogleAnalyticsV2Init} />}
      {shouldPTInit && <PinterestPixel />}
      {shouldTwitterInit && <TwitterPixel />}
      {shouldTikTokInit && <TikTokPixel />}
      {(shouldClarityInit || shouldClarityV2Init) && <Clarity initSecondClarity={shouldClarityV2Init} />}
      {isDevelopment && <DebugUi />}
    </>;
});