import { useMutation } from '@tanstack/react-query';

import { createSubscription } from '@features/Stripe/api';
import { TpStripeCreateSubscription } from '@features/Stripe/types';
import { useGetAppName } from 'src/utils/hooks';

export const useCreateSubscription = () => {
  const app = useGetAppName();
  return useMutation({
    mutationFn: (variables: TpStripeCreateSubscription) =>
      createSubscription({ ...variables, app }),
    mutationKey: ['create-subscription'],
  });
};
