import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import { ReactNode, useMemo } from "react";
import { useStores } from "src/components/common/root-store-provider/root-store-provider";
import { FlagsProvider } from "./FlaggedUtil";
import { SCANNER_TEST_NAME } from "src/constants/analytics";
export const FEATURE_FLAGS = (["scannerFeature", "usFeature", "introOfferFeature"] as const);
const INTRO_OFFER_VARIANTS_SET = new Set(['variant20', 'variant21', 'variant22', 'google21']);
const startsWithB = (inputString: string | undefined | null): boolean => /^B/.test(inputString || '');
export const FeatureFlagProvider = observer(({
  children
}: {
  children: ReactNode;
}) => {
  const {
    quizStore: {
      countryCode
    },
    analyticsStore: {
      getCaseNameByTestName
    },
    authStore: {
      variant
    }
  } = useStores();
  const {
    query
  } = useRouter();
  const featuresFromQuery = useMemo(() => {
    if (typeof window === 'undefined') return [];
    const featuresFromStorage = sessionStorage.getItem('features')?.split(',') || [];
    if (query.features) {
      const features = typeof query.features === 'string' ? query.features.split(',') : query.features;
      sessionStorage.setItem('features', features.toString());
      return features;
    }
    return featuresFromStorage;
  }, [query.features]);
  const isScannerEnabled = useMemo(() => startsWithB(getCaseNameByTestName(SCANNER_TEST_NAME)), [getCaseNameByTestName]);
  const isVariantUsEnabled = useMemo(() => (variant === 'MimikaUS' || variant === 'googleUS') && countryCode === 'US', [variant, countryCode]);
  const isIntroOfferEnabled = useMemo(() => INTRO_OFFER_VARIANTS_SET.has(variant ?? '') || isVariantUsEnabled, [variant, isVariantUsEnabled]);
  const features = useMemo((): Record<(typeof FEATURE_FLAGS)[number], boolean> => ({
    scannerFeature: isScannerEnabled,
    usFeature: isVariantUsEnabled,
    introOfferFeature: isIntroOfferEnabled,
    ...Object.fromEntries(featuresFromQuery.map(feature => [feature, true]))
  }), [isScannerEnabled, isVariantUsEnabled, isIntroOfferEnabled, featuresFromQuery]);
  return <FlagsProvider features={features}>{children}</FlagsProvider>;
});