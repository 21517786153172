import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

const COOKIE_KEY = 'extId';
const COOKIE_EXPIRY_DAYS = 365;

export const useExternalId = (): string | null => {
  const { query, isReady } = useRouter();
  const [externalId, setExternalId] = useState<string | null>(null);

  const generateRandomId = useCallback((): string => {
    return Math.floor(Math.random() * 100_000_000).toString();
  }, []);

  useEffect(() => {
    // Не начинаем работу, пока `useRouter` не готов
    if (!isReady) return;

    // Проверяем значение в query параметрах
    const queryExternalId = Array.isArray(query.externalId)
      ? query.externalId[0]
      : query.externalId;

    if (queryExternalId) {
      // Если ID есть в query, сохраняем его в куки и state
      if (queryExternalId !== Cookies.get(COOKIE_KEY)) {
        Cookies.set(COOKIE_KEY, queryExternalId, {
          expires: COOKIE_EXPIRY_DAYS,
        });
      }
      setExternalId(queryExternalId);
      return;
    }

    // Если ID уже есть в cookies, используем его
    const cookieExternalId = Cookies.get(COOKIE_KEY);
    if (cookieExternalId) {
      setExternalId(cookieExternalId);
      return;
    }

    // Если ID нет ни в query, ни в cookies, генерируем новый
    const newId = generateRandomId();
    Cookies.set(COOKIE_KEY, newId, { expires: COOKIE_EXPIRY_DAYS });
    setExternalId(newId);
  }, [isReady, query, generateRandomId]);

  // Всегда возвращаем строку (или пустую строку в крайних случаях)
  return externalId;
};
