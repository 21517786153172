import { TpModalProps } from '@mentalgrowth/ui-kit-web';
import classNames from 'classnames';
import { ReactNode, useCallback } from 'react';
import ModalContainer from '../modal-container/modal-container';
import styles from './modal.module.scss';
export type TpComponentModalProps = TpModalProps & {
  showCloseButton?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
  handleCloseModal?: () => void | null;
  showMobileHeader?: boolean;
};
const Modal = ({
  showCloseButton = true,
  title,
  description,
  footer,
  children,
  handleCloseModal,
  ...props
}: TpComponentModalProps) => {
  const {
    onHide
  } = props;
  const handleClose = useCallback(() => {
    handleCloseModal && handleCloseModal();
    onHide && onHide();
  }, [handleCloseModal, onHide]);
  return <ModalContainer className={classNames(styles.container)} canClose={showCloseButton} closeButtonAlign="right" onHide={handleClose} {...props} data-sentry-element="ModalContainer" data-sentry-component="Modal" data-sentry-source-file="modal.tsx">
      {title && <h2 className={styles.title}>{title}</h2>}
      {description && <p className={styles.description}>{description}</p>}
      {children}
      {footer && <div className={styles.footerContainer}>{footer}</div>}
    </ModalContainer>;
};
export default Modal;