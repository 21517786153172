import braintree, { ApplePayPaymentRequest } from 'braintree-web';
import { useAtomValue } from 'jotai';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import type { SubscriptionPlan } from 'src/models/subscription-plan/subscription-plan';
import { useAnalytics } from '@features/Analytics';
import { createBraintreeSubscription } from '@features/Payments/api/payments-api';
import { braintreeClientTokenAtom } from '@features/Payments/atoms';
import BraintreeModal from '@features/Payments/components/braintree-modal/braintree-modal';
import { AnalyticsPaymentMethod, PaymentCompletionStatus, usePaymentCompletionStatus } from '@features/Payments';
import { useGetAppName } from 'src/utils/hooks';
export enum PaymentSystem {
  BRAINTREE,
}
type TpApplePayExtended = ApplePayPaymentRequest & {
  total: {
    label: string;
    amount: string;
  };
  currencyCode: string;
};
const BRAINTREE_APPLE_PAY_STORE_NAME = 'Youth';
export const usePaymentSystem = ({
  subscriptionPlan,
  paymentSystem,
  setIsLoading,
  onLoadEnd,
  braintreeOptions = {},
  shouldFetch = true
}: {
  subscriptionPlan: SubscriptionPlan;
  paymentSystem?: PaymentSystem;
  setIsLoading: (value: boolean) => void;
  onLoadEnd?: () => void;
  braintreeOptions?: {
    useApplePay?: boolean;
    useGooglePay?: boolean;
    isApplePayFromPaywall?: boolean;
  };
  shouldFetch?: boolean;
}): {
  contentNode?: ReactNode;
  openCheckout: () => Promise<void>;
  openCheckoutWithoutGesture: () => Promise<void>;
} => {
  const {
    authStore
  } = useStores();
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    user,
    auth_token
  } = authStore;
  const app = useGetAppName();
  const braintreeClientToken = useAtomValue(braintreeClientTokenAtom);
  const {
    setPaymentCompletionStatus
  } = usePaymentCompletionStatus();

  // #region Braintree specific

  // The braintree client for Apple Pay must be loaded in advance,
  // because Safari allows to initiate an Apple Pay session only
  // inside a gesture handler
  const braintreePaymentClient = useRef<braintree.Client | undefined>();
  const fetchBraintreePaymentClient = useCallback(async () => {
    setIsLoading(true);
    await (async () => {
      try {
        if (!braintreeClientToken) {
          return;
        }
        braintreePaymentClient.current = await braintree.client.create({
          authorization: braintreeClientToken
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        onLoadEnd?.();
      }
    })();
  }, [braintreeClientToken, onLoadEnd, setIsLoading]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: braintreePaymentClient is not changing
  useEffect(() => {
    if (braintreePaymentClient.current === undefined) {
      fetchBraintreePaymentClient().then(r => r);
    }
  }, [braintreePaymentClient, fetchBraintreePaymentClient]);
  const [braintreeShowModal, setBraintreeShowModal] = useState(false);
  const braintreeHandleModalClose = () => {
    setBraintreeShowModal(false);
  };
  const handleBraintreeCheckoutComplete = useCallback(async (paymentMethodNonce: string, paymentMethodVaulted?: boolean) => {
    try {
      setPaymentCompletionStatus({
        value: PaymentCompletionStatus.SUCCESS,
        selectedPlanId: subscriptionPlan.id
      });
      await createBraintreeSubscription({
        customerId: user!.id.toString(),
        customerToken: auth_token!,
        merchantAccountId: subscriptionPlan.merchantAccountId,
        planId: subscriptionPlan.braintreePlanId!,
        paymentMethodNonce,
        paymentMethodVaulted,
        app
      });
      // This prevents flashing of the alert with a loading message,
      // because the subscription is expected to activate right away
      // in most cases
      await authStore.fetchUser();
    } catch (error) {
      setPaymentCompletionStatus({
        value: PaymentCompletionStatus.FAILURE,
        selectedPlanId: subscriptionPlan.id
      });
      console.error(error);
    } finally {
      setBraintreeShowModal(false);
    }
  }, [app, setPaymentCompletionStatus, subscriptionPlan.id, subscriptionPlan.merchantAccountId, subscriptionPlan.braintreePlanId, user, auth_token, authStore]);

  // endregion

  const openCheckout = useCallback(async () => {
    if (paymentSystem == null) {
      return;
    }
    if (!user) {
      throw new Error('A user is required');
    }
    setIsLoading(true);
    switch (paymentSystem) {
      case PaymentSystem.BRAINTREE:
        try {
          if (braintreeOptions.useApplePay) {
            const client = braintreePaymentClient.current!;
            const applePay = await braintree.applePay.create({
              client
            });
            const paymentRequest = applePay.createPaymentRequest(({
              total: {
                label: subscriptionPlan.name,
                amount: subscriptionPlan.immediatePrice.toFixed(2)
              },
              currencyCode: subscriptionPlan.currency
            } as TpApplePayExtended));
            const session = ApplePaySession.supportsVersion(14) ?
            // @ts-ignore (an issue with library typings)
            new ApplePaySession(14, paymentRequest) :
            // @ts-ignore (an issue with library typings)
            new ApplePaySession(3, paymentRequest);
            session.onvalidatemerchant = async event => {
              const merchantSession = await applePay.performValidation({
                validationURL: event.validationURL,
                displayName: BRAINTREE_APPLE_PAY_STORE_NAME
              });
              session.completeMerchantValidation(merchantSession);
            };
            session.onpaymentauthorized = async event => {
              let nonce: string;
              try {
                ({
                  nonce
                } = await applePay.tokenize({
                  token: event.payment.token
                }));
              } catch (error) {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                setPaymentCompletionStatus({
                  value: PaymentCompletionStatus.FAILURE,
                  selectedPlanId: subscriptionPlan.id
                });
                console.error(error);
                return;
              }
              try {
                await handleBraintreeCheckoutComplete(nonce, false);
              } finally {
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
              }
            };
            session.begin();
            session.oncancel = () => {
              trackGoogleEvent({
                eventName: 'add_payment_info_skipped',
                options: {
                  payment_system: 'braintree',
                  payment_method: AnalyticsPaymentMethod.APPLE_PAY
                }
              });
            };
          } else if (braintreeOptions.useGooglePay) {
            const client = braintreePaymentClient.current!;
            const googlePay = await braintree.googlePayment.create({
              client: client,
              googlePayVersion: 2,
              googleMerchantId: 'BCR2DN4TRLM4XIDR'
            });
            const paymentDataRequest = await googlePay.createPaymentDataRequest({
              transactionInfo: {
                currencyCode: (subscriptionPlan.currency as string),
                totalPriceStatus: 'FINAL',
                totalPrice: subscriptionPlan.immediatePrice.toFixed(2)
              }
            });
            const paymentsClient = new google.payments.api.PaymentsClient({
              environment: (process.env.NEXT_PUBLIC_GOOGLE_PAY_ENV as 'PRODUCTION' | 'TEST')
            });
            const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
            cardPaymentMethod.parameters.billingAddressRequired = false;
            try {
              // const isReadyToPay = await paymentsClient.isReadyToPay({
              //   // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest for all options
              //   apiVersion: 2,
              //   apiVersionMinor: 0,
              //   allowedPaymentMethods:
              //     googlePay.createPaymentDataRequest().allowedPaymentMethods,
              //   existingPaymentMethodRequired: true,
              // });
              // console.log(isReadyToPay.result);
              const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest);
              googlePay.parseResponse(paymentData, (error, result) => {
                if (error) {
                  console.error(error);
                }
                handleBraintreeCheckoutComplete(result.nonce, false);
              });
            } catch (error) {
              console.error(error);
              //there u can handle a user close googlePay window
              trackGoogleEvent({
                eventName: 'add_payment_info_skipped',
                options: {
                  payment_system: 'braintree',
                  payment_method: AnalyticsPaymentMethod.GOOGLE_PAY
                }
              });
            }
          } else {
            setBraintreeShowModal(true);
          }
        } finally {
          setIsLoading(false);
        }
        break;
    }
  }, [paymentSystem, user, setIsLoading, braintreeOptions.useApplePay, braintreeOptions.useGooglePay, subscriptionPlan.name, subscriptionPlan.immediatePrice, subscriptionPlan.currency, subscriptionPlan.id, setPaymentCompletionStatus, handleBraintreeCheckoutComplete, trackGoogleEvent]);
  const openCheckoutWithoutGesture = useCallback(async () => {
    switch (paymentSystem) {
      case PaymentSystem.BRAINTREE:
        if (braintreeOptions.useApplePay) {
          return;
        }
        break;
    }
    await openCheckout();
  }, [braintreeOptions.useApplePay, openCheckout, paymentSystem]);

  // Rendering necessary elements

  let contentNode = <BraintreeModal subscriptionPlan={subscriptionPlan} show={braintreeShowModal} onHide={braintreeHandleModalClose} clientToken={braintreeClientToken} onCheckoutComplete={handleBraintreeCheckoutComplete} />;
  return {
    contentNode,
    openCheckout,
    openCheckoutWithoutGesture
  };
};