import { useMutation } from '@tanstack/react-query';

import { modifySubscription } from '@features/Stripe/api';
import { TpModifySubscription } from '@features/Stripe/api/modifySubscription';
import { useGetAppName } from 'src/utils/hooks';

export const useModifySubscription = () => {
  const app = useGetAppName();
  return useMutation({
    mutationFn: (variables: TpModifySubscription) =>
      modifySubscription({ ...variables, app }),
    mutationKey: ['modify-subscription'],
  });
};
