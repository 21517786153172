import classNames from "classnames";
import { memo } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { CurrentPrice } from "src/features/Paywall/widgets/subscription-card/current-price";
import { PriceUnitFull } from "src/features/Paywall/widgets/subscription-card/price-unit-full";
import { getDataFromSessionStorage } from "src/utils";
import { SubscriptionCardMaster } from "@features/Paywall";
import { BillingPeriod } from "@features/Paywall/widgets/subscription-card/billing-period";
import { DiscountPercentage } from "@features/Paywall/widgets/subscription-card/discount-percentage";
import { FullPrice } from "@features/Paywall/widgets/subscription-card/full-price";
import { TpStripePlanCardProps } from "@features/Stripe/types";
import styles from "./subscription-plan-card-old.module.scss";
export const SubscriptionPlanCardStripeOld = memo((props: TpStripePlanCardProps) => {
  const {
    plan,
    disabled = false,
    wasPaymentTried,
    planCardClassname
  } = props;
  const currentVariant = getDataFromSessionStorage({
    store: "authStore",
    key: "variant"
  });
  return <SubscriptionCardMaster {...props} hidePlanTitle={false} planCardClassname={classNames(styles.planCard, planCardClassname)} contentClassname={styles.content} leftBottomContent={(value: boolean) => !disabled && <div className={styles.wrapper}>
              <div className={classNames(styles.text, {
      [styles.textChecked]: value
    })}>
                {plan?.fractionalPrice !== plan?.fractionalPriceDiscount && <FullPrice plan={plan} />}{" "}
                <FullPrice withDiscount plan={plan} />{" "}
                <BillingPeriod plan={plan} />
              </div>

              <div className={styles.discountPercentage}>
                {wasPaymentTried && plan.preselected && <del className={styles.defaultDiscount}>
                    {plan.specialDiscountPercentage}%
                  </del>}
                {plan?.discountPercentage !== 0 && plan?.discountPercentage != null && <DiscountPercentage variant={currentVariant === "variant80" ? "withText" : "default"} discountPercentage={plan.discountPercentage} isActive={value && !wasPaymentTried} wasPaymentTried={value && wasPaymentTried} />}
              </div>
            </div>} rightContent={(value: boolean) => <div className={styles.alignEnd}>
            <div className={styles.rightBlockContainer}>
              {plan?.fractionalPrice !== plan?.fractionalPriceDiscount && <PriceUnitFull plan={plan} checked={value} className={styles.priceUnitFull} />}{" "}
              <CurrentPrice plan={plan} checked={value} />
            </div>
          </div>} />;
});
SubscriptionPlanCardStripeOld.displayName = 'SubscriptionPlanCardStripeOld';