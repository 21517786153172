import { FormattedMessage } from '@features/intl';
import classNames from 'classnames';
import { memo } from 'react';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import styles from './upsell-agree-and-decline-buttons.module.scss';
type TpUpsellAgreeAndDeclineButtonsPros = {
  disabled?: boolean;
  handleBuy: () => void;
  handleDecline: () => void;
  className?: string;
  testIdBuyButton: string;
  testIdDeclineButton: string;
};
export const UpsellAgreeAndDeclineButtons = memo(({
  disabled = false,
  handleBuy,
  testIdBuyButton,
  testIdDeclineButton,
  handleDecline,
  className
}: TpUpsellAgreeAndDeclineButtonsPros) => {
  return <div className={classNames(styles.buttonsContainer, className)}>
        <UiKitButton disabled={disabled} fullWidth className={styles.payButton} onClick={handleBuy} data-testid={testIdBuyButton}>
          <FormattedMessage defaultMessage="Try Premium for free" id="Onboarding.Upsale.Button" />
        </UiKitButton>

        <UiKitButton as="a" color="pure" data-testid={testIdDeclineButton} className={styles.declineButton} onClick={handleDecline}>
          <FormattedMessage defaultMessage="Decline the offer" id="Onboarding.Upsale.Decline" />
        </UiKitButton>
      </div>;
});