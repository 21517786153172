import classNames from "classnames";
import { useMemo } from "react";
import { currencyReplacer } from "@features/Payments";
import { TpCardPlanChildrenProps } from "@features/Paywall/types";
import { useFeature } from "../../../../FeatureToggle";
import styles from "./full-price.module.scss";
type TpFullPriceProps = {
  withDiscount?: boolean;
  plan: TpCardPlanChildrenProps["plan"];
};
export const FullPrice = (props: TpFullPriceProps) => {
  const {
    withDiscount,
    plan
  } = props;
  //TODO: Delete after cleanup tests
  const isIntroOfferEnabled = useFeature('introOfferFeature');
  const currencySymbol = currencyReplacer(plan?.currency);
  const resultPrice = useMemo(() => {
    if (withDiscount) {
      return isIntroOfferEnabled ? Number(plan?.introOfferData?.fullPriceDiscount).toFixed(2) : plan?.fullPriceDiscount;
    } else {
      return isIntroOfferEnabled ? plan?.introOfferData?.fullPrice : plan?.fullPrice;
    }
  }, [plan?.fullPrice, plan?.fullPriceDiscount, plan?.introOfferData?.fullPrice, plan?.introOfferData?.fullPriceDiscount, isIntroOfferEnabled, withDiscount]);
  return <span className={classNames({
    [styles.lineThrough]: !withDiscount
  })} data-sentry-component="FullPrice" data-sentry-source-file="index.tsx">{`${currencySymbol}${resultPrice}`}</span>;
};