import { useAtomValue } from "jotai";
import { useAtom } from "jotai/index";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo } from "react";
import { Image } from "src/components/common/Image";
import CloseButton from "src/components/common/close-button/close-button";
import Alert from "src/components/common/modal/alert/alert";
import { UiKitButton } from "src/components/common/ui-kit-button";
import { useAnalytics } from "@features/Analytics";
import { currencyReplacer } from "@features/Payments";
import { noMoneyPlanInfoAtom, stripePaymentErrorAtom } from "@features/Stripe/atoms";
import styles from "./payment-declined-modal.module.scss";
export const PaymentDeclinedModal = ({
  onClose
}: {
  onClose: () => void;
}) => {
  const [{
    wasErrorModalWithOfferShown,
    declineCode,
    errorCode,
    paymentSystem,
    paymentMethod
  }, setPaymentError] = useAtom(stripePaymentErrorAtom);
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    query
  } = useRouter();
  const noMoneyInfo = useAtomValue(noMoneyPlanInfoAtom);
  const showErrorModal = useMemo(() => !wasErrorModalWithOfferShown && (errorCode === "insufficient_funds" || errorCode === "card_declined" && (declineCode === "do_not_honor" || declineCode === "insufficient_funds" || declineCode === "generic_decline")), [declineCode, errorCode, wasErrorModalWithOfferShown]);
  const handleModelClose = useCallback(() => {
    trackGoogleEvent({
      eventName: "purchase_bank_declined_tapped"
    });
    onClose();
    setPaymentError(previous => ({
      ...previous,
      wasErrorModalWithOfferShown: true,
      errorCode: "",
      declineCode: "",
      paymentMethod: "",
      paymentSystem: ""
    }));
  }, [onClose, setPaymentError, trackGoogleEvent]);
  const handleModelDismiss = useCallback(() => {
    trackGoogleEvent({
      eventName: "purchase_bank_declined_dismissed"
    });
    setPaymentError(previous => ({
      ...previous,
      wasErrorModalWithOfferShown: false,
      errorCode: "",
      declineCode: "",
      paymentMethod: "",
      paymentSystem: ""
    }));
  }, [setPaymentError, trackGoogleEvent]);
  useEffect(() => {
    if (showErrorModal) {
      trackGoogleEvent({
        eventName: "purchase_bank_declined_opened",
        options: {
          payment_system: paymentSystem ?? "undefined",
          payment_type: paymentMethod ?? "undefined",
          product_id: query["plan"] ?? "undefined"
        }
      });
    }
  }, [paymentMethod, paymentSystem, query, showErrorModal, trackGoogleEvent]);
  return <Alert show={showErrorModal} onHide={handleModelClose} image={<Image src="oops-ice-cream.svg" width={142} height={109} alt="oops-ice-cream" />} className={styles.modalContent} data-sentry-element="Alert" data-sentry-component="PaymentDeclinedModal" data-sentry-source-file="index.tsx">
      <CloseButton className={styles.closeButton} onClick={handleModelDismiss} data-sentry-element="CloseButton" data-sentry-source-file="index.tsx" />
      <h2 className={styles.title}>
        Your bank could not process the payment this time
      </h2>
      <p className={styles.subTitle}>
        But we&apos;re here to ensure your reflection stays fabulous!
      </p>
      <div className={styles.buttonContentContainer}>
        <p className={styles.buttonContentTitle}>
          Meanwhile, here&apos;s a little treat!
        </p>
        <p className={styles.buttonContentSubTitle}>
          Enjoy one full month just for{" "}
          <mark className={styles.priceLabel}>
            {currencyReplacer(noMoneyInfo.currency)}{" "}
            {noMoneyInfo.fullPriceDiscount}
          </mark>
        </p>
        <UiKitButton data-testid="decline-agree-button" onClick={handleModelClose} fullWidth className={styles.button} data-sentry-element="UiKitButton" data-sentry-source-file="index.tsx">
          Let&apos;s Try Again - {currencyReplacer(noMoneyInfo.currency)}
          {noMoneyInfo.fullPriceDiscount} Only!
        </UiKitButton>
      </div>
    </Alert>;
};