import { PrivacyAgreement } from '@features/Paywall/widgets/payment-plans/components/privacy-agreement';
import { UpsellSharedModalProps } from '@features/Stripe/widgets/upsell-modal-renderer';
import { NowAndLaterPremiumPrices } from '@features/Upsell/components/now-and-later-premium-prices';
import { PremiumHeader } from '@features/Upsell/components/premium-header';
import { PremiumPlansCompareSection } from '@features/Upsell/components/premium-plans-compare-section';
import { UpsellAgreeAndDeclineButtons } from '@features/Upsell/components/upsell-agree-and-decline-buttons';
import { UpsellPolicy } from '@features/Upsell/components/upsell-policy';
import { memo, useCallback, useEffect, useState } from 'react';
import Modal from 'src/components/common/modal/modal/modal';
import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';
import { useAnalytics } from '@features/Analytics';
import { selectedPlanAtom, userAtom } from '@features/Stripe/atoms';
import { useModifySubscription } from '@features/Stripe/hooks/mutation/use-modify-subscription';
import { UpsellLoader } from '@features/Upsell/components/upsell-loader';
import { FormattedMessage } from '@features/intl';
import { useAtomValue } from 'jotai/index';
import styles from './upsell-premium.module.scss';
export const UpsellPremium = memo(({
  onNext
}: UpsellSharedModalProps) => {
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(true);
  const {
    mutateAsync: modifySubscription
  } = useModifySubscription();
  const {
    currency,
    fullPriceDiscount,
    premiumPriceData,
    billingPeriodUnit
  } = useAtomValue(selectedPlanAtom);
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    email,
    userId
  } = useAtomValue(userAtom);
  const handleBuy = useCallback(() => {
    trackGoogleEvent({
      eventName: 'premium-recommend_button_try-premium_click'
    });
    if (premiumPriceData?.upgradeId) {
      modifySubscription({
        email,
        userId,
        newPlanId: premiumPriceData?.upgradeId
      }).then(r => r);
      onNext('MODAL_UPSELL');
    }
  }, [onNext, modifySubscription, email, userId, premiumPriceData?.upgradeId, trackGoogleEvent]);
  const handleDecline = useCallback(() => {
    trackGoogleEvent({
      eventName: 'premium-recommend_button_decline_click'
    });
    onNext('PREMIUM_INFO_STATIC_ALERT');
  }, [onNext, trackGoogleEvent]);
  useEffect(() => {
    trackGoogleEvent({
      eventName: 'premium-recommend_screen__loaded'
    });
  }, [trackGoogleEvent]);
  return <Modal modalContentClassName={styles.container} show={true} canClose={false}>
      {!premiumPriceData ? <UpsellLoader /> : <div className={styles.contentWrapper}>
          <PremiumHeader />

          <div className={styles.compareSectionWrapper}>
            <PremiumPlansCompareSection currency={currency} currentPrice={fullPriceDiscount} periodUnit={billingPeriodUnit} premiumCurrentPrice={0.0} premiumOldPrice={premiumPriceData.fullPriceDiscount} trialDays={premiumPriceData.introOfferData.introOfferDuration} />
          </div>

          <NowAndLaterPremiumPrices currency={currency} currentPrice={0.0} premiumDayDuration={premiumPriceData?.introOfferData.introOfferDuration} featurePrice={premiumPriceData.fullPriceDiscount} />

          <UpsellPolicy />

          <PrivacyAgreement agreementText={<>
                <FormattedMessage defaultMessage="I agree to our {PrivacyPolicy} {TOS}" id="Onboarding.Upsale.Agree" values={{
          PrivacyPolicy: <>
                        <PolicyLink place="upsale" /> <br />
                      </>,
          TOS: <TermsLink place="upsale">
                        <FormattedMessage defaultMessage="Terms of Service" id="Onboarding.Upsale.TOS" />
                      </TermsLink>
        }} />{' '}
                <FormattedMessage defaultMessage="and {RefundPolicy}" id="Onboarding.Upsale.AgreeAnd" values={{
          RefundPolicy: <RefundLink place="upsale">
                        <FormattedMessage defaultMessage="Refund Policy" id="Onboarding.Upsale.RefundPolicy" />
                      </RefundLink>
        }} />
              </>} className={styles.privacyAgreement} isChecked={isPrivacyChecked} onChecked={() => setIsPrivacyChecked(!isPrivacyChecked)} />

          <UpsellAgreeAndDeclineButtons testIdBuyButton="premium-buy-button" testIdDeclineButton="premium-decline-button" handleBuy={handleBuy} handleDecline={handleDecline} disabled={!isPrivacyChecked} />
        </div>}
    </Modal>;
});
UpsellPremium.displayName = 'UpsellPremium';