import { useFeature } from '@features/FeatureToggle';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMemo } from 'react';

const STRIPE_API_KEY = process.env.NEXT_PUBLIC_STRIPE_API_KEY as string;
const STRIPE_API_KEY_US = process.env.NEXT_PUBLIC_STRIPE_API_KEY_US as string;

const initializeStripe = async (apiKey: string): Promise<Stripe | null> => {
  return await loadStripe(apiKey);
};

let stripeInstance: Stripe | null = null;

const getStripe = async (apiKey: string): Promise<Stripe | null> => {
  if (!stripeInstance) {
    stripeInstance = await initializeStripe(apiKey);
  }
  return stripeInstance;
};

export const useGetStripePromise = () => {
  const isVariantUsEnabled = useFeature('usFeature');

  return useMemo(
    () => getStripe(isVariantUsEnabled ? STRIPE_API_KEY_US : STRIPE_API_KEY),
    [isVariantUsEnabled],
  );
};
