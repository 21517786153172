import { axiosMicroservice } from 'src/api/axios';
import { TpStripeCreateSubscription } from 'src/features/Stripe/types';

export const createSubscription = async ({
  priceId,
  userId,
  currency,
  email,
  token,
  introOfferId,
  introOfferDuration,
  campaign,
  app = 'FaceYoga',
}: TpStripeCreateSubscription): Promise<{
  client_secret: string;
  customer_id: string;
}> => {
  const { data } = await axiosMicroservice.post('stripe/create_subscription', {
    price_id: priceId,
    user_id: userId,
    currency: currency,
    email: email,
    token: token,
    intro_offer_id: introOfferId,
    intro_offer_duration: introOfferDuration,
    campaign: campaign,
    app,
  });
  return data;
};
