import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useMemo } from 'react';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { FACEBOOK_PIXEL_ID, FACEBOOK_PIXEL_ID_ADQUANTUM, FACEBOOK_PIXEL_ID_CPM_TEST, FACEBOOK_PIXEL_ID_UNI, FACEBOOK_PIXEL_ID_V17, FACEBOOK_PIXEL_ID_V18 } from '../../constants';
const FbPixel = observer(({
  externalId
}: {
  externalId: string;
}) => {
  const {
    authStore: {
      variant
    },
    analyticsStore: {
      setFbPixelId
    }
  } = useStores();
  const router = useRouter();
  const {
    isReady,
    query
  } = router;
  const fbPixelIds: Array<string | undefined> | null = useMemo(() => {
    if (isReady && typeof window !== 'undefined' && window.location.hostname === 'quiz.mimika-uni.com' && query['reset'] === undefined) {
      return [FACEBOOK_PIXEL_ID_UNI];
    }
    if (isReady && variant === 'variant16' && query['reset'] === undefined) {
      return [FACEBOOK_PIXEL_ID, FACEBOOK_PIXEL_ID_CPM_TEST, FACEBOOK_PIXEL_ID_ADQUANTUM];
    }
    if (isReady && variant === 'variant17' && query['reset'] === undefined) {
      return [FACEBOOK_PIXEL_ID_V17];
    }
    if (isReady && variant === 'variant18' && query['reset'] === undefined) {
      return [FACEBOOK_PIXEL_ID_V18];
    }
    return isReady && query['reset'] === undefined ? [FACEBOOK_PIXEL_ID] : null;
  }, [isReady, query, variant]);
  useEffect(() => {
    if (fbPixelIds != null && !fbPixelIds.includes(undefined)) {
      setFbPixelId((fbPixelIds as string[]));
    }
  }, [fbPixelIds, setFbPixelId]);
  return fbPixelIds ? <>
      <Script id="facebook-pixel">
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq.disablePushState = true;
        ${fbPixelIds.map(id => `fbq('init', ${id}, {'extern_id': '${externalId}'});`).join('')}
        fbq('track', 'PageView');`}
      </Script>
      {fbPixelIds.map((id, index) => <noscript key={id + String(index)}>
          <img style={{
        display: 'none'
      }} height="1" width="1" src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`} alt="" />
        </noscript>)}
    </> : null;
});
export default FbPixel;