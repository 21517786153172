import { languageDetector } from '@features/intl';
import { DEFAULT_LOCALE } from '@features/intl/contants';

const LOCALES_DATA = [
  { locale: 'en', prefix: '' },
  { locale: 'es', prefix: '-esp' },
  { locale: 'fr', prefix: '-fr' },
  { locale: 'de', prefix: '-de' },
  { locale: 'pt', prefix: '-pt' },
];

export const getLocaleImage = () => {
  const currentLocale = languageDetector.detect() || DEFAULT_LOCALE;
  const currentLocaleTwoSymbols = currentLocale.slice(0, 2);
  return (
    LOCALES_DATA.find((locale) => locale.locale === currentLocaleTwoSymbols)
      ?.prefix || ''
  );
};
