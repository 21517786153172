import { ComponentProps, ReactNode, useCallback } from 'react';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import Alert from '../../../common/modal/alert/alert';
import styles from './alert-with-close-button.module.scss';
const AlertWithCloseButton = ({
  closeButtonText = 'OK',
  footer,
  buttonVariant,
  ...props
}: {
  closeButtonText?: ReactNode;
} & ComponentProps<typeof Alert>) => {
  const {
    onHide
  } = props;
  const handleClose = useCallback(() => {
    onHide && onHide();
  }, [onHide]);
  return <Alert footer={<>
          {footer}
          <UiKitButton className={styles.bottomButton} fullWidth variant={buttonVariant} type="button" onClick={handleClose}>
            {closeButtonText}
          </UiKitButton>
        </>} {...props} data-sentry-element="Alert" data-sentry-component="AlertWithCloseButton" data-sentry-source-file="alert-with-close-button.tsx" />;
};
export default AlertWithCloseButton;