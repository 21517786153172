import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

import {
  braintreeClientTokenAtom,
  generateBraintreeClientToken,
} from '@features/Payments';
import { TpGenerateBraintreeClientToken } from '@features/Payments/api/generateBraintreeClientToken';
import { useGetAppName } from 'src/utils/hooks';

export const useGenerateBraintreeClientToken = ({
  userFields,
  merchantAccountId,
}: {
  userFields: { email?: string; id?: number | string };
  merchantAccountId?: number | string | null;
}): string | null => {
  const queryClient = useQueryClient();
  const app = useGetAppName();
  const [braintreeClientToken, setBraintreeClientToken] = useAtom(
    braintreeClientTokenAtom,
  );

  const mutationCallback = useCallback(
    (variables: TpGenerateBraintreeClientToken) =>
      generateBraintreeClientToken({
        ...variables,
        app,
      }),
    [app],
  );

  const { mutateAsync: mutation } = useMutation({
    mutationFn: mutationCallback,
    mutationKey: ['generate-braintree-client-token'],
    onSuccess: (data: string) => {
      setBraintreeClientToken(data);
      queryClient.invalidateQueries({
        queryKey: ['stripe-subscriptions'],
      });
    },
  });

  useEffect(() => {
    if (
      userFields.id &&
      userFields.email &&
      merchantAccountId &&
      braintreeClientToken === ''
    ) {
      mutation({
        customerEmail: userFields.email,
        customerId: userFields.id.toString(),
        merchantAccountId: merchantAccountId,
      }).then((response: string) => setBraintreeClientToken(response));
    }
  }, [
    braintreeClientToken,
    merchantAccountId,
    mutation,
    setBraintreeClientToken,
    userFields.email,
    userFields.id,
  ]);

  return braintreeClientToken;
};
